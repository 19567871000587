<template>
  <div :data-help="computedHelp" class="x-text-field">
    <v-text-field
        v-if="!tooltip"
        ref="input"
        :value="value"
        :clearable="clearable"
        :color="color"
        :counter="maxLength"
        :disabled="disabled"
        :label="label"
        :rules="computedRules"
        :type="type"
        :id="id"
        :prepend-icon="prependIcon"
        :append-icon="appendIcon"
        :readonly="readonly"
        :placeholder="placeholder"
        class="x-input"
        :style="`${pxOrNot('width', width)}; ${pxOrNot('max-width', width)};`"
        dense
        hide-details="auto"
        outlined
        @input="handleInput"
        @click="$emit('click')"
        @focus="handleFocus(true)"
        @blur="handleFocus(false)"
        @keydown="handleKeyDown"
        @keydown.up.prevent
        @keydown.down.prevent>
      <template #append>
        <slot name="append"/>
      </template>
      <template v-slot:append-outer>
        <slot name="append-outer"/>
      </template>
    </v-text-field>
    <v-tooltip v-else v-model="showTooltip" top>
      <template v-slot:activator="{}">
        <v-text-field
            ref="input"
            :value="value"
            :clearable="clearable"
            :color="color"
            :counter="maxLength"
            :disabled="disabled"
            :label="label"
            :rules="computedRules"
            :type="type"
            :id="id"
            :prepend-icon="prependIcon"
            :append-icon="appendIcon"
            :readonly="readonly"
            :placeholder="placeholder"
            class="x-input"
            :style="`${pxOrNot('width', width)}; ${pxOrNot('max-width', width)};`"
            dense
            hide-details="auto"
            outlined
            @focus="handleFocus(true)"
            @blur="handleFocus(false)"
            @mouseenter="mouse = true"
            @mouseleave="mouse = false"
            @input="handleInput"
            @click="$emit('click')"
            @keydown="handleKeyDown"
            @keydown.up.prevent
            @keydown.down.prevent>
          <template #append>
            <slot name="append"/>
          </template>
          <template v-slot:append-outer>
            <slot name="append-outer"/>
          </template>
        </v-text-field>
      </template>
      <span v-if="tooltip" v-html="tooltip"/>
    </v-tooltip>
    <HelpButton :id="id" :help="computedHelp"/>
  </div>
</template>

<script>
import getRule from '@/js/rules';
import HelpButton from '@/components/basic/HelpButton';
import {getRequiredRule, pxOrNot} from '@/js/general';

export default {
  name: 'XTextField',
  components: {HelpButton},
  props: {
    value: [String, Number],
    label: String,
    required: Boolean,
    rules: Array,
    tooltip: String,
    clearable: Boolean,
    validateImmediately: Boolean,
    disabled: Boolean,
    color: String,
    type: String,
    id: String,
    name: String,
    maxLength: [Number, String],
    prependIcon: String,
    appendIcon: String,
    readonly: Boolean,
    keepValue: Boolean,
    autocomplete: String,
    delay: [Boolean, Number, String],
    placeholder: String,
    default: [String, Number],
    width: [String, Number],
    help: String,
  },
  data() {
    return {
      focus: false,
      getRequiredRule: getRequiredRule,
      mouse: false,
      lastInput: new Date(),
      lastCaretPosition: 0,
    };
  },
  mounted() {
    if (this.validateImmediately) {
      const oldValue = this.value;
      //this.$emit('input', ' ');
      if (this.value == '') {
        this.$emit('input', ' ');
      }
      this.$nextTick(() => {
        this.$emit('input', oldValue);
      });
    } else {
      this.emitDefaultValue();
    }
    const input = this.$refs.input.$refs.input;
    input.name = this.name;

    this.$emit('validate', this.$refs.input.validate());
  },
  watch: {
    tooltip() {
      setTimeout(() => {
        this.$refs.input.$refs.input.focus();
        this.$refs.input.$refs.input.setSelectionRange(this.lastCaretPosition, this.lastCaretPosition);
      }, 16);
    },
    value() {
      if (this.$refs.input) {
        this.$emit('validate', this.$refs.input.validate());
      }
    },
  },
  computed: {
    computedRules() {
      let computedRules = [];
      if (this.required) {
        computedRules.push(getRequiredRule());
      }
      if (this.maxLength) {
        computedRules.push(v => !v || v.toString().length <= this.maxLength);
      }
      if (this.type === 'float') {
        computedRules.push(v => !v || !isNaN(parseFloat(v)) || 'Please enter a decimal number like 5 or 3.1415.');
      }
      if (this.rules) {
        for (const rule of this.rules) {
          if (typeof rule === 'string') computedRules.push(getRule(rule));
          else computedRules.push(rule);
        }
      }
      if (!computedRules.length) computedRules = undefined;
      return computedRules;
    },
    showTooltip() {
      return this.focus || this.mouse;
    },
    computedDelay() {
      if (typeof this.delay === 'string') return parseInt(this.delay);
      if (typeof this.delay === 'number') return this.delay;
      if (typeof this.delay === 'boolean' && this.delay) return 1000;
      return 0;
    },
    computedHelp() {
      if (this.help) return this.help;
      return this.id;
    },
  },
  methods: {
    pxOrNot,
    handleInput(value) {
      if (value === null) value = '';
      this.lastInput = new Date();

      const emitInput = () => {
        if (this.delay && new Date().getTime() - this.lastInput < this.computedDelay) return;
        const oldValue = this.value;
        const input = this.$refs.input;
        if (input) this.lastCaretPosition = input.$refs.input.selectionStart;

        let typedValue = value;
        if (this.type === 'int') {
          typedValue = parseInt(typedValue);
          if (!isNaN(typedValue)) this.$emit('input', typedValue);
        } else if (this.type === 'float') {
          typedValue = parseFloat(typedValue);
          if (!isNaN(typedValue)) this.$emit('input', typedValue);
        } else if (this.type === 'int-string') {
          if (typedValue === '') this.$emit('input', null);
          else {
            typedValue = parseInt(typedValue);
            if (!isNaN(typedValue)) this.$emit('input', typedValue);
            else this.$emit('input', this.value);
          }
        } else {
          this.$emit('input', typedValue);
        }
        if (this.keepValue) {
          this.$nextTick(() => {
            this.$emit('input', oldValue);
          });
        }
      };

      if (!this.delay) {
        emitInput();
      } else {
        this.lastCaretPosition = this.$refs.input.$refs.input.selectionStart;
        this.$emit('immediate-input', value);
        setTimeout(emitInput, this.computedDelay);
      }
    },
    handleFocus(value) {
      this.focus = value;
      if (value) this.$emit('focus');
      else this.$emit('blur');
    },
    handleKeyDown(value) {
      if (this.type === 'int-string') {
        const validKeys = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete'];
        const isNumber = event.key >= '0' && event.key <= '9';

        if (!isNumber && !validKeys.includes(event.key)) {
          event.preventDefault();
        }
      }

      if (value.key === 'ArrowLeft' || value.key === 'ArrowRight') {
        setTimeout(() => {
          this.lastCaretPosition = this.$refs.input.$refs.input.selectionStart;
        }, 16);
      }
      this.$emit('keydown', value);
    },
  },
};
</script>

<style scoped>
.x-text-field {
  display: flex;
}

.x-text-field >>> .help-button {
  margin-top: 2px;
}

/deep/ .x-input.v-text-field.v-text-field--enclosed > .v-input__control > .v-text-field__details {
  margin-bottom: -3px;
}
</style>