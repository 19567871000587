<template>
  <div>
    <div :style="headerStyle">
      <mainProgress :loading="loading"></mainProgress>
      <upperHeader
          v-if="userInfo != null && !$vuetify.breakpoint.mobile"
          :userInfo="userInfo"
          :result="result"
          :menu="menu"
      ></upperHeader>
      <mobileMenu v-model="drawer" :menu="menu" :key="getMenuKey()" :userInfo="userInfo"></mobileMenu>
      <v-app-bar color="primary" id="navHeader" style class="navbar">
        <v-app-bar-nav-icon
            v-if="
          userInfo != null &&
          userInfo.loggedIn === true &&
          $vuetify.breakpoint.mobile
        "
            @click.stop="drawer = !drawer"
        ></v-app-bar-nav-icon>
        <v-toolbar-title>
        <span v-if="$vuetify.breakpoint.smAndDown">
        <v-img
            v-if="$vuetify.breakpoint.smAndDown"
            class=""
            width="150"
            id="logo"
            src="img/logo.png"
        ></v-img>
        </span>

          <v-img v-else id="logo" src="img/logo.png"></v-img>
        </v-toolbar-title>
        <parentMenu
            v-if="!$vuetify.breakpoint.mobile"
            class="ml-5"
            :menu="menu"
            :key="getMenuKey()"
        ></parentMenu>
        <v-spacer></v-spacer>
        <v-toolbar-title>
          <v-img
              v-if="$vuetify.breakpoint.smAndDown"
              max-height="60"
              max-width="50"
              src="img/infoLogo.png"
          ></v-img>
          <v-img
              v-else
              max-height="50"
              max-width="150"
              src="img/degustLogoHome.png"
          ></v-img>
        </v-toolbar-title>
      </v-app-bar>
      <!-- mini bar! -->
      <!-- <v-app-bar color="primary" id="miniNavHeader">
              <img class="tinyLogo" src="img/logo.png">
              <parentMenu
                      class="miniParentMenu"
                      :menu="menu"
                      :key="getMenuKey()"
              ></parentMenu>
              <v-spacer></v-spacer>
              <upperHeader
                      class="miniTopHeader"
                      v-if="userInfo!=null"
                      :userInfo="userInfo"
                      :result="result"
                      :menu="menu"
                      style=" background-color: #2b5593 !important;"
              ></upperHeader>
      </v-app-bar>-->
    </div>
    <demoMessageCard
        v-if="checkDemoProject"
        :userInfo="userInfo"
        :result="result"
    />
  </div>
</template>
<script>
import upperHeader from '@/components/legacy/upperHeader.vue';
import parentMenu from '@/commonComponents/parentMenu.vue';
import mainProgress from '@/commonComponents/mainProgress.vue';
import mobileMenu from '@/commonComponents/mobileMenu.vue';
import demoMessageCard from '@/components/dynamic/demoMessageCard.vue';

export default {
  components: {
    upperHeader,
    parentMenu,
    mainProgress,
    mobileMenu,
    demoMessageCard,
  },
  data() {
    return {drawer: false};
  },
  computed: {
    checkDemoProject(){
      return  this.userInfo != null ? this.userInfo.isDemoProject: false;
    },
    checkRestrictedPage() {
      let resultSet = true;
      // console.log(this.result);
      if (this.result != null &&
          typeof this.result.contentID != 'undefined' &&
          this.result.contentID === 'changePassword') {
        resultSet = false;
      }
      return resultSet;
    },
    headerStyle() {
      return this.smallHeader ? 'height: 92px' : '';
    },
  },
  methods: {
    getMenuKey: function () {
      let index = 1;
      if (this.menu != null) {
        index = Object.keys(this.menu).length;
      }
      return index;
    },
  },
  // mounted() {
  //   this.$nextTick(function(){
  //     window.addEventListener("scroll", function(){
  //       var navbar = document.getElementById("miniNavHeader");
  //       var nav_classes = navbar.classList;
  //       console.log(document.documentElement.scrollTop);
  //       if(document.documentElement.scrollTop >= 100) {
  //         if (nav_classes.contains("shrink") === false) {
  //           nav_classes.toggle("shrink");
  //         }
  //       }
  //       else {
  //         if (nav_classes.contains("shrink") === true) {
  //           nav_classes.toggle("shrink");
  //         }
  //       }
  //     });
  //   });
  // },
  props: ['userInfo', 'result', 'menu', 'loading', 'smallHeader'],
};
</script>