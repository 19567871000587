<template>
  <div>
    <v-menu
        v-for="(mainMenuItem, index) in cleanedIterator"
        :key="index"
        :close-on-content-click="false"
        open-on-hover
        bottom
        offset-y
        :close-delay="200"
        v-model="open[index]"
    >
      <template v-slot:activator="{ on }">
        <template v-if="typeof mainMenuItem['type'] != 'undefined' &&
                  mainMenuItem['type'] === 'upperLink'">

          <router-link
              v-if="typeof mainMenuItem['features'].granted != 'undefined' &&
                  mainMenuItem['features'].granted === 'active'"
              :to="mainMenuItem.attr.href"
          >
            <v-btn
                depressed
                color="primary"
                :icon="$vuetify.breakpoint.xs"
                :id="index+'_main_menu'"
                :x-small=" $vuetify.breakpoint.smAndDown"
            >
              <template v-if="!$vuetify.breakpoint.xs">{{ language[mainMenuItem.name] }}</template>
            </v-btn>
          </router-link>
        </template>
        <template v-else
        >
          <v-btn
              :x-small=" $vuetify.breakpoint.smAndDown"
              color="primary"
              depressed
              v-on="on"
              :icon="$vuetify.breakpoint.xs"
              :id="index+'_main_menu'"
          >
            <v-icon v-if="$vuetify.breakpoint.xs" color="white">{{ mainMenuItem.icon }}
            </v-icon
            >
            <template v-if="!$vuetify.breakpoint.xs">{{ language[mainMenuItem.name] }}</template>
          </v-btn
          >
        </template>
      </template>
      <v-card v-if="typeof mainMenuItem['type'] == 'undefined' ||
                  mainMenuItem['type'] !== 'upperLink' ">
        <v-list>
          <v-list-item>
            <div class="megaMenu">
              <div
                  v-if="
                  typeof mainMenuItem['type'] != 'undefined' &&
                  mainMenuItem['type'] === 'layers'
                "
              >
                <v-tabs v-model="tab" right>
                  <v-tab
                      v-for="(menuItem, tabIndex, count) in mainMenuItem[
                      'children'
                    ]"
                      :key="tabIndex"
                      @mouseover="changeTab(count)"
                      @mouseout="resetTab(count)"
                  >{{ language[menuItem.name] }}
                  </v-tab
                  >

                  <v-tabs-items v-model="tab">
                    <v-tab-item
                        v-for="(menuItem, tabIndex) in mainMenuItem['children']"
                        :key="tabIndex"
                    >
                      <v-card flat>
                        <v-card-text>
                          <megaMenu
                              v-if="typeof menuItem['children'] != 'undefined'"
                              :submenuList="menuItem['children']"
                              :enableTabs="
                              getEnableTabs(mainMenuItem['children'])
                            "
                              :mainMenuItem="mainMenuItem"
                              :menuItem="menuItem"
                          ></megaMenu>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                  </v-tabs-items>
                </v-tabs>
              </div>
              <div v-else>
                <megaMenu
                    :recentTestList="recentTestList"
                    :submenuList="mainMenuItem['children']"
                    :enableTabs="false"
                    :tabIndex="0"
                    :mainMenuItem="mainMenuItem"
                    :menuItem="{}"
                ></megaMenu>
              </div>
            </div>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>
<script>
import megaMenu from "@/commonComponents/megaMenu.vue";
import requests from '@/js/requests';
//import menuLink from "@/commonComponents/menuLink.vue";

export default {
  components: {
    megaMenu,
    //  menuLink
  },
  data: function () {
    return {
      tab: 0,
      activeTab: 0,
      activeCalled: false,
      isHidden: false,
      open: {},
      recentTestList: [],
      intervalIds: [],
    };
  },
  props: ["iterator"],
  computed: {
    cleanedIterator: function () {
      // deep copy to keep origin intact
      let cleanedIterator = {...this.iterator};
      this.$delete(cleanedIterator, "systemAdministration");
      //console.log(cleanedIterator);
      return cleanedIterator;
    },
  },
  mounted() {
    this.getRecentTests();
  },
  watch: {
    $route() {
      this.open = {};
    },
    "open.testing": function (newVal) {// open is true
      this.customSetInterval(this.getRecentTests, 3000);
      if (!newVal) {
        this.clearAllCustomInterval();
      }
    },
  },
  methods: {
    customSetInterval: function (fn, delay) {
      let id = setInterval(fn, delay);
      this.intervalIds.push(id);
      return id;
    },
    clearAllCustomInterval: function () {
      while (this.intervalIds.length) {
        clearTimeout(this.intervalIds.pop());
      }
    },
    getRecentTests() {
      let caller = this;
      requests.frameworkAxiosRequest({
        method: 'POST',
        url: 'serve.php?f=testing&f2=configureAndRunTests&function=getRecentTests',
        dataType: 'json',
        data: {
          responseType: 'ajax',
        },
      })
          .then((response) => {
            if (response?.data?.result?.json?.recentTests) {
              caller.recentTestList = response.data.result.json.recentTests;
            }
          })
          .catch((error) => {
            console.log(error);
          });
    },
    getEnableTabs: function (menuItem) {
      let enableTabs = false;
      if (Object.keys(menuItem).length > 1) {
        enableTabs = true;
      }
      return enableTabs;
    },
    removeItem: function (menuList, value) {
      if (menuList != null && typeof menuList[value] != "undefined") {
        delete menuList[value];
      }
      return menuList;
    },
    changeTab: function (tabIndex) {
      this.activeCalled = setTimeout(
          function () {
            //  if (this.activeTab == tabIndex) {
            this.tab = tabIndex;
            // }
          }.bind(this),
          100
      );
    },
    resetTab: function () {
      clearTimeout(this.activeCalled);
    },
  },
};
</script>
