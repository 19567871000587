<template>
  <div class="x-chart">
    <VueCharts
      v-if="hasData"
      :option="computedValue.options"
      :theme="acctopusLightTheme"
      autoresize
      @click="handleClick"
    />

    <div v-else-if="computedValue && computedValue.options && computedValue.options.title" class="x-chart-title">
      {{ computedValue.options.title.text }}
    </div>

    <div v-if="!hasData && !loading" class="x-chart-no-data">
      <div>
        No data for the given time range.
      </div>
    </div>

    <XThrobber v-if="loading" class="x-chart-throbber"/>
  </div>
</template>

<script>
import { defineComponent } from "vue"
import {use} from 'echarts/core';
import {CanvasRenderer} from 'echarts/renderers';
import {LineChart, PieChart, BarChart} from 'echarts/charts';
import {TitleComponent, TooltipComponent, LegendComponent, GridComponent, ToolboxComponent} from 'echarts/components';
import VueCharts from 'vue-echarts';
import acctopusLightTheme from '@/js/acctopus-light.json';
import XThrobber from '@/components/basic/XThrobber.vue';

use([
  CanvasRenderer,
  LineChart,
  PieChart,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
]);

export default defineComponent({
  name: 'XChart',

  components: {
    XThrobber,
    VueCharts,
  },

  props: {
    name: String,
    value: Object,
    request: Function,
    requestParams: Array,
    range: Object,
    refresh: Number,
  },

  data() {
    return {
      loaded: false,
      dataValue: null,
      tick: null,
      elapsed: 0,
      loading: false,
      acctopusLightTheme: acctopusLightTheme,
    };
  },

  mounted() {
    this.tick = setInterval(() => {
      if (!this.refresh || this.loading) return;
      this.elapsed++;
      if (this.elapsed >= this.refresh) {
        this.elapsed = 0;
        this.reloadData();
      }
    }, 1000);
    if (!this.refresh) this.reloadData();
  },

  beforeDestroy() {
    clearInterval(this.tick);
  },

  watch: {
    request() {
      this.reloadData();
    },

    requestParams: {
      deep: true,
      handler() {
        this.reloadData();
      },
    },

    range: {
      deep: true,
      handler() {
        this.reloadData();
      },
    },
  },

  computed: {
    computedValue() {
      return this.value ? this.value : this.dataValue;
    },

    hasData() {
      if (this.computedValue) {
        const options = this.computedValue.options;
        if (options && options.series) {
          if (options.series.type === '' || options.series.type === 'pie') {
            return !!options.series.data;
          } else {
            return !!Object.keys(options.series).length;
          }
        }
      }
      return false;
    },
  },

  methods: {
    reloadData() {
      if (!this.request || !this.range) return;
      this.loading = true;
      this.request(...this.requestParams, this.range, (value) => {
            if (!value || !value.options || !value.options.series) {
              value = {
                options: {
                  title: {
                    text: this.name,
                    textStyle: {
                      fontWeight: 'normal',
                    },
                    left: 0,
                  },
                  backgroundColor: '#fff',
                  legend: {
                    data: [],
                    align: 'left',
                    left: 'left',
                    icon: 'pin',
                    backgroundColor: 'transparent',
                    bottom: true,
                    type: 'scroll',
                  },
                  tooltip: {
                    show: true,
                    trigger: 'axis',
                  },
                  xAxis: {
                    type: 'time',
                    data: [],
                    axisLabel: {
                      showMinLabel: false,
                    },
                  },
                  yAxis: [
                    {
                      type: 'value',
                      name: 'Mbps',
                      nameLocation: 'start',
                      axisLabel: {
                        showMinLabel: false,
                      },
                    },
                    {
                      type: 'value',
                      name: 'Time',
                      nameLocation: 'start',
                      axisLabel: {
                        showMinLabel: false,
                      },
                    },
                  ],
                  series: [],
                  grid: {
                    left: 50,
                    top: 35,
                    right: 35,
                    bottom: 45,
                  },
                  toolbox: {
                    right: 10,
                    feature: {
                      dataZoom: {
                        show: true,
                        yAxisIndex: 'none',
                      },
                      restore: {
                        show: true,
                      },
                      saveAsImage: {
                        show: true,
                      },
                    },
                  },
                },
              };
            }
            this.dataValue = value;
            this.loading = false;
            this.loaded = true;
          },
      );
    },

    handleClick(params) {
      if (this.value.events && this.value.events.click) {
        for (const event of this.value.events.click) {
          if (this.checkCondition(params.data, event.conditions)) {
            this.executeAction(event.action);
            return;
          }
        }
      }
    },

    checkCondition(data, conditions) {
      for (const condition of conditions) {
        switch (condition.operator) {
          case '==':
            if (data[condition.field] !== condition.value) return false;
            break;
          case '<':
            if (data[condition.field] >= condition.value) return false;
            break;
          case '<=':
            if (data[condition.field] > condition.value) return false;
            break;
          case '>':
            if (data[condition.field] <= condition.value) return false;
            break;
          case '>=':
            if (data[condition.field] < condition.value) return false;
            break;
          case '!=':
            if (data[condition.field] === condition.value) return false;
            break;
        }
      }
      return true;
    },

    executeAction(action) {
      if (action.link) {
        this.safeClick(action.link);
      }
    },
  },
});
</script>

<style scoped>
.x-chart {
  position: relative;
}

.x-chart,
.x-chart > div {
  width: 100%;
  height: 100%;
}

.x-chart-title {
  padding: 0 5px;
  font-size: 18px;
}

.x-chart-no-data {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.x-chart-no-data > div {
  color: var(--v-no-records-base);
  width: 75%;
  user-select: none;
}

.x-chart-throbber {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
