<template>
  <div style="height: 500px">
    <v-container fill-height fluid>
      <v-row align="center" justify="center">
        <v-col style="max-width: 800px">
          <v-card>
            <div class="pt-7 d-flex flex-column justify-space-between align-center">
              <v-img max-width="200" src="img/dl.png"></v-img>
            </div>
            <v-card-title class="text-h5">
              {{ title }}
            </v-card-title>
            <v-card-text>
              {{ text }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'PageError',
  props: {
    title: String,
    text: String,
  },
};
</script>

<style scoped>

</style>