<template>
  <div class="dashboard-widget">
    <div v-if="(!loading || initialized) && !reload">
      <div ref="content" class="dashboard-widget-content">
        <div v-if="value.type === 'info'">Info</div>
        <TestStatus
            v-else-if="value.type === 'teststatus'"
            :title="widgetName"
            :height="size ? size.height : 0"
            :custom-range="range"
            :refresh="refresh"
            no-page-controls
            :show-test-name="value.showTestName"
            :show-legend="value.showLegend"
            :searchText="value.search"
            :items-per-page="20"
            only-scheduled
            @loading="$emit('loading', $event)"/>
        <div v-else-if="value.type === 'echart'">
          <XChart v-if="type === 'echart'" :name="value.name" :value="widgetData"/>

          <DashboardMapClustered
            v-else-if="type === 'explorerMap'"
            :options="widgetData"
            :style="editMode ? 'z-index: 0' : ''"
          />

          <DashboardMap
            v-else-if="type === 'explorerMapByTag'"
            :options="widgetData"
            :style="editMode ? 'z-index: 0' : ''"
          />
        </div>
      </div>
      <div v-if="editMode" class="edit-mode-overlay">
        <div class="edit-overlay-title-bar">
          <div class="edit-overlay-title">{{ widgetName }}</div>
          <x-btn icon="mdi-pencil" @click="$emit('edit', value.i)"/>
          <x-btn icon="mdi-delete" @click="$emit('delete', value.i)"/>
        </div>
        <v-spacer/>
      </div>
    </div>

    <div v-else class="dashboard-widget-progress-circular">
      <XThrobber/>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue"
import XBtn from '@/components/basic/XBtn.vue';
import TestStatus from '@/components/specific/TestStatus/TestStatus.vue';
import XChart from '@/components/basic/XChart.vue';
import DashboardMapClustered from '@/components/basic/DashboardMapClustered.vue';
import DashboardMap from '@/components/basic/DashboardMap.vue';
import XThrobber from '@/components/basic/XThrobber.vue';
import chartService from '@/js/services/ChartService';
import {deepEquals} from '@/js/general';

export default defineComponent({
  name: 'DashboardWidget',
  components: {
    XThrobber,
    DashboardMapClustered,
    DashboardMap,
    XChart,
    TestStatus,
    XBtn,
  },
  props: {
    value: Object,
    editMode: Boolean,
    filters: [Object, Function],
    size: Object,
    range: Object,
    refresh: Number,
    reload: Boolean,
    public: Boolean,
    uuid: String,
    projectApiKey: String,
  },
  data() {
    return {
      type: 'none',
      loaded: false,
      attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      widgetData: {},
      tick: null,
      elapsed: 0,
      loading: true,
      initialized: false,
      widgetName: '',
    };
  },
  mounted() {
    this.tick = setInterval(() => {
      if (!this.range || !this.refresh || this.loading) return;
      this.elapsed++;
      if (this.elapsed >= this.refresh) {
        this.elapsed = 0;
        this.reloadData();
      }
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.tick);
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.reloadData();
      },
    },
    range() {
      this.reloadData();
    },
    refresh() {
      this.reloadData();
    },
    loading: {
      immediate: true,
      handler(value) {
        this.$emit('loading', value);
      },
    },
    filters: {
      deep: true,
      handler(value, oldValue) {
        if (!deepEquals(value, oldValue)) {
          this.reloadData();
        }
      },
    },
  },
  methods: {
    reloadData() {
      if (this.value.type === 'teststatus') this.widgetName = 'Test Status';
      this.widgetName = this.value.name;

      if (!this.value.chartId || !this.range || this.type === 'info') {
        this.loading = false;
        return;
      }

      this.loading = true;

      const now = Math.trunc(new Date().getTime() / 1000);
      const from = !this.range.seconds ? Math.trunc(this.range.from.getTime() / 1000) : now - this.range.seconds;
      const to = !this.range.seconds ? Math.trunc(this.range.to.getTime() / 1000) : now;

      const getChartById = !this.public ? chartService.getChartById : chartService.getChartByIdPublic;

      let filter = {
        ...((typeof this.filters === 'function' ? this.filters(this.value) : this.filters) || {}),
        ...(this.value.filter || {}),
      };

      getChartById(this.value.chartId, {
        title: this.value.name ? this.value.name : '',
        from: from,
        to: to,
        preview: false,
        filter: filter,
        'project-api-key': this.projectApiKey,
      }, (response) => {
        if (response.type === 'explorerMap') {
          this.widgetData = response;
          this.type = 'explorerMap';
        } else if (response.type === 'explorerMapByTag') {
          this.widgetData = response;
          this.type = 'explorerMapByTag';
        } else if (Object.hasOwn(response, "options") && Object.hasOwn(response.options, "series")) {
          const options = response.options;
          options.title.text = this.value.name;
          this.widgetData = response;
          this.type = 'echart';
        } else {
          this.type = 'echart';
        }
        this.loading = false;
        this.initialized = true;
      }, () => {
        this.loading = false;
      });
    },
  },
});
</script>

<style scoped>
.dashboard-widget {
  width: 100%;
  height: 100%;
  background-color: white;
}

.dashboard-widget > div,
.dashboard-widget-content,
.dashboard-widget-content > div,
.dashboard-widget-content > div > div,
.dashboard-widget-progress-circular,
.edit-mode-overlay {
  width: 100%;
  height: 100%;
}

.dashboard-widget-progress-circular {
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-mode-overlay {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  z-index: 1000;
}

.edit-overlay-title-bar {
  display: flex;
  align-items: center;
  flex: 0 1 auto;
  background-color: lightgray;
}

.edit-overlay-title {
  padding-left: 10px;
  width: calc(100% - 72px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
