import Vue from 'vue';
import Notifications from 'vue-notification';
import App from './App.vue';
import VueRouter from 'vue-router';
import VueGeoLocation from 'vue-browser-geolocation';
import * as VueGoogleMaps from 'vue2-google-maps';
import vuetify from '@/plugins/vuetify';
import 'vuetify/dist/vuetify.min.css'; // Ensure you are using css-loader
import axios from 'axios';
import VueAxios from 'vue-axios';
import Vuex from 'vuex';
import 'leaflet/dist/leaflet.css';

import vuejquery from 'vue-jquery';
import AsyncComputed from 'vue-async-computed';

import {mixin} from '@/js/mixin.js';
import {useStore} from '@/store';

import VTooltip from 'v-tooltip';
import defaultValueMixin from '@/mixins/default-value-mixin';
import notificationMixin from '@/mixins/notification-mixin';

Vue.use(VTooltip);

Vue.use(Notifications);

Vue.use(AsyncComputed);
Vue.use(VueRouter);
Vue.config.productionTip = false;

// 1. Define route components.
// These can be imported from other files
//const Foo = { template: '<div>foo</div>' }
//const Bar = { template: '<div>bar</div>' }

// 2. Define some routes
// Each route should map to a component. The "component" can
// either be an actual component constructor created via
// `Vue.extend()`, or just a component options object.
// We'll talk about nested routes later.
const routes = [
    //{ path: '/foo', component: Foo },
    //{ path: '/bar', component: Bar }
];

// while on PHP navigation, to avoid annoying navigation errors:
// TODO: Remove once migrate away from PHP navigation
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    if (err.name !== 'NavigationDuplicated') throw err
  });
};

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
//
const router = new VueRouter({
    mode: 'history',
    routes, // short for `routes: routes`
});

Vue.use(vuejquery);
Vue.use(Vuex);

Vue.mixin(mixin);
Vue.mixin(defaultValueMixin);
Vue.mixin(notificationMixin);

// axios.defaults.headers = {
//     'Access-Control-Allow-Origin': 'http://localhost:8080',
//     'Content-Type': 'application/json',
// };
Vue.use(VueAxios, axios);

Vue.use(VueGeoLocation);
Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyCKmrnIZ_WcrXQ5IXrFwfQ0RmG7NbsGYy0',
        libraries: 'places',
    },
});

new Vue({
    vuetify,
    router,
    store: useStore(),
    render: h => h(App),
}).$mount('#app');
