import { ref, computed, onBeforeMount } from "vue"
import testStatus from '@/cfg/testStatus.json';
import { useRouter, useRoute } from "vue-router/composables"

/**
 * @typedef {import('vue').Ref} Ref
 */

/**
  * @typedef {Object} StatusesCounts
  * @property {number} total
  * @property {number=} aborted
  * @property {number=} maxRuntime
  * @property {number=} ok
  * @property {number=} pending
  * @property {number=} processingError
  * @property {number=} processingWarning
  * @property {number=} resultError
  * @property {number=} resultWarning
  * @property {number=} running
  */

/**
 * @typedef {Object} StatusRepesentation
 * @property {number} value
 * @property {string} key
 * @property {string} color
 * @property {string} text
 */

export const DEFAULT_STATUS = {
  value: -1,
  key: "total",
  color: "dark",
  text: "Total: 0",
}
export const STATUS_PARAM_NAME = "status"

export function useStatusBarSelect({ multiple = false, possibleStatuses = testStatus } = {}) {
  /*
   * @type {Ref<StatusesCounts>}
   */
  const statusesCounts = ref({})

  /*
   * @type {Ref<number[] | []>}
   */
  const statusesFilter = ref(multiple ? [] : DEFAULT_STATUS.value)

  const generateSplits = ({ statusesDesc = testStatus, getText = (_sd) => _sd.text } = {}) => {
    return statusesDesc.map(s => ({
      value: s.id,
      key: s.name,
      color: s.color,
      text: getText(s)
    }))
  }

  /*
   * @type {Ref<StatusRepesentation[] | []>}
   */
  const statusesRepr = computed(() => {
    const list = []

    if (!Object.keys(statusesCounts.value).length) {
      return list;
    }

    const sortedTestStatus = Object.values(possibleStatuses).filter((ts) => {
      return Object.prototype.hasOwnProperty.call(statusesCounts.value, ts.name)
    }).sort((a, b) => a.order - b.order);

    return list.concat(generateSplits({
      statusesDesc: sortedTestStatus,
      getText: (s) => `${s.text}: ${statusesCounts.value[s.name]}`
    }))
  })

  const statusTotal = computed(() => {
    if (!Object.keys(statusesCounts.value).length) {
      return DEFAULT_STATUS;
    }

    const st = { ...DEFAULT_STATUS }
    st.text = st.text.replace("0", statusesCounts.value.total)

    return st
  })

  const statusesAsParamString = computed(() => {
    if (!multiple && statusesFilter.value !== DEFAULT_STATUS.value) {
      return statusesFilter.value.toString()
    }

    if (!multiple) {
      return ""
    }

    if (statusesFilter.value.length === 0) {
      return statusTotal.value.value.toString()
    }

    return statusesFilter.value.join(',')
  })

  const selectTotalStatuses = () => {
    if (!multiple) {
      statusesFilter.value = DEFAULT_STATUS.value
      return
    }
    statusesFilter.value = []
  }

  return {
    selectTotalStatuses,
    statusesCounts,
    statusesFilter,
    statusesRepr,
    statusTotal,
    statusesAsParamString,
    generateSplits,
  }
}

/**
 * If you need to reflect changes in the URL, use this function
 */
export function useStatusBarSelectWithRouter({ multiple = false, possibleStatuses = testStatus } = {}) {
  const {
    statusesCounts,
    statusesFilter,
    statusesRepr,
    statusTotal,
    statusesAsParamString,
    generateSplits,
  } = useStatusBarSelect({ multiple, possibleStatuses })

  const route = useRoute()
  const router = useRouter()

  const getFilterFromQuery = () => {
    const q = route.query
    const statuses = q[STATUS_PARAM_NAME]

    if (!statuses) {
      return multiple ? [] : DEFAULT_STATUS.value
    }

    if (multiple) {
      return statuses.split(",").map(s => parseInt(s))
    }

    return parseInt(statuses)
  }

  const statusesFilterWithRouter = computed({
    get() {
      return statusesFilter.value
    },
    /**
    * @param {number[] | number} statuses
    */
    set(statuses) {
      const newQuery = { ...route.query }

      if (!multiple && statuses !== DEFAULT_STATUS.value) {
        newQuery[STATUS_PARAM_NAME] = statuses.toString()
      } else if (multiple && Array.isArray(statuses) && statuses.length > 0) {
        newQuery[STATUS_PARAM_NAME] = statuses.join(",")
        // remove statuses bc it is either -1 or []
      } else if (!multiple || (multiple && Array.isArray(statuses) && statuses.length === 0)) {
        delete newQuery[STATUS_PARAM_NAME]
      }

      router.replace({ ...route, query: newQuery })
        .catch((err) => {
          if (err.name !== "NavigationDuplicated") {
            throw err
          }
        })

      statusesFilter.value = statuses
    }
  })

  const selectTotalStatusesWithRouter = () => {
    if (!multiple) {
      statusesFilterWithRouter.value = DEFAULT_STATUS.value
      return
    }
    statusesFilterWithRouter.value = []
  }

  onBeforeMount(() => {
    statusesFilter.value = getFilterFromQuery()
  })

  return {
    statusesFilter: statusesFilterWithRouter,
    selectTotalStatuses: selectTotalStatusesWithRouter,

    statusesRepr,
    statusTotal,
    statusesAsParamString,
    generateSplits,
    statusesCounts,
  }
}
