import fiberNetCfg from '@/cfg/fiberNet.json';
import {deepReplace} from '@/js/general';

let enabled = JSON.parse(localStorage.getItem('fiberNet'));

const fiberNet = {
    getEnabled() {
        return enabled;
    },
    setEnabled: (value) => {
        enabled = value;
        localStorage.setItem('fiberNet', value);
    },
    replace: (data) => {
        if (enabled) {
            for (const replacement of fiberNetCfg) {
                data = deepReplace(data, replacement.searchValue, replacement.replaceValue,
                    replacement.caseSensitive,
                );
            }
        }
        return data;
    },
};

export default fiberNet;