import Service from '@/js/service.js';

const service = new Service(`${process.env.VUE_APP_TEST_CASE_INFO_SERVICE}/testcase-info-service`);

const testCaseInfoService = {
    createScheduledTest(testCase, then, error) {
        testCase.viewingHistory.createDate = Math.floor(Date.now() / 1000);
        testCase.viewingHistory.version = 0;
        service.putRequest(1, 'scheduled-test', testCase, then, error);
    },
    getExplorerTests(explorerId, testStatus, params, then, error) {
        if (!explorerId) explorerId = 0;
        const combinedParams = {
            'test-status': testStatus !== undefined ? testStatus : -1, ...params,
        };
        service.getRequest(1, 'explorer-tests/{id}', combinedParams, then, error, explorerId);
    },
    getExplorerTestsStatusCounts(explorerId, params, then, error) {
        service.getRequest(1, 'explorer-tests/{id}/counts', params, then, error, explorerId);
    },
    getTestCaseCategories(testCaseId, then, error) {
        if (!testCaseId) testCaseId = 0;
        service.getRequest(1, 'tests/{id}/categories', null, then, error, testCaseId);
    },
    getCategories(then, error) {
        service.getRequest(1, 'tests/categories', null, then, error);
    },
    getTestCase(id, then, error) {
        service.getRequest(1, 'tests/{id}/testcase', null, then, error, id);
    },
    getTestcases(categories, params, then, error) {
        const combinedParams = {
            categories: categories !== undefined ? categories : '', ...params,
        };
        service.getRequest(1, 'tests/testcases', combinedParams, then, error);
    },
    getTestCases(then, error) {
        service.getRequest(1, 'tests/testcases', null, then, error);
    },
    getTestCasesWithResourceType(then, error) {
        service.getRequest(1, 'tests/testcases/with-resource-type', null, then, error);
    },
    getTestCasesWithResourceTypeByType(type, then, error) {
        service.getRequest(1, 'tests/testcases/with-resource-type', {type: type}, then, error);
    },
    getScheduledTestFromTestCase(id, then, error) {
        service.getRequest(1, '{id}/scheduled-test-from-testcase', null, then, error, id);
    },
    saveCategories(testCaseId, newCategories, then, error) {
        service.patchRequest(1, 'tests/{id}/save-categories', newCategories, then, error, testCaseId);
    },
    getSubscriber(subscriberId, then, error) {
        if (!subscriberId) subscriberId = 0;
        service.getRequest(1, 'tests/{id}/subscriber', null, then, error, subscriberId);
    },
    getSubscriberCategories(then, error) {
        service.getRequest(1, 'tests/subscriber-categories', null, then, error);
    },
    newSubscriber(subscriber, then, error) {
        subscriber.viewingHistory.createDate = Math.floor(Date.now() / 1000);
        service.putRequest(1, 'tests/new-subscriber', subscriber, then, error);
    },
    updateSubscriber(subscriber, then, error) {
        subscriber.viewingHistory.modifyDate = Math.floor(Date.now() / 1000);
        service.patchRequest(1, 'tests/{id}/update-subscriber', subscriber, then, error, subscriber.id);
    },
    copyTestCase(destProjectHash, testCase, then, error) {
        testCase.createDate = Math.floor(Date.now() / 1000);
        service.putRequest(1, 'tests/{dest-hash}/copy-testcase', testCase, then, error, destProjectHash);
    },
    deleteTestCase(id, then, error) {
        service.deleteRequest(1, '{id}/delete-testcase', null, then, error, id);
    },
    getAllActiveProbes(then, error) {
        service.getRequest(1, 'tests/active-probes', null, then, error);
    },
    getLocalServers(serverType, then, error) {
        service.getRequest(1, 'tests/{type}/local-servers', null, then, error, serverType);
    },
    newLocalServer(newServer, then, error) {
        newServer.viewingHistory.createDate = Math.floor(Date.now() / 1000);
        service.putRequest(1, 'tests/new-local-server', newServer, then, error);
    },
    getLDAPContents(id, params, then, error) {
        service.getRequest(1, 'tests/{id}/ldap-contents', params, then, error, id);
    },
    getRawLDAPContents(id, then, error) {
        service.getRequest(1, 'tests/{id}/raw-ldap-contents', null, then, error, id);
    },
    saveLDAPContent(subscriberId, newLdap, then, error) {
        newLdap.viewingHistory.modifyDate = Math.floor(Date.now() / 1000);
        service.patchRequest(1, 'tests/{id}/save-ldap-content', newLdap, then, error, subscriberId);
    },
    newLDAPContent(newLdap, then, error) {
        newLdap.viewingHistory.createDate = Math.floor(Date.now() / 1000);
        service.putRequest(1, 'tests/new-ldap-content', newLdap, then, error);
    },
    deleteLDAPContent(id, then, error) {
        service.deleteRequest(1, 'tests/{id}/delete-ldap-content', null, then, error);
    },
    getHSSObjects(id, params, then, error) {
        service.getRequest(1, 'tests/{id}/hss-objects', params, then, error, id);
    },
    saveHSSObject(subscriberId, newHss, then, error) {
        newHss.viewingHistory.modifyDate = Math.floor(Date.now() / 1000);
        service.patchRequest(1, 'tests/{id}/save-hss-object', newHss, then, error, subscriberId);
    },
    newHSSObject(newHss, then, error) {
        newHss.viewingHistory.createDate = Math.floor(Date.now() / 1000);
        service.putRequest(1, 'tests/new-hss-object', newHss, then, error);
    },
    getRawHSSObjects(id, then, error) {
        service.getRequest(1, 'tests/{id}/raw-hss-objects', null, then, error, id);
    },
    deleteHSSObject(id, then, error) {
        service.deleteRequest(1, 'tests/{id}/delete-hss-object', null, then, error, id);
    },
    getScheduledTest(id, then, error) { // (id, version, then, error) {
        service.getRequest(1, '{id}/{version}/scheduled-test', null, then, error, id, 1);
    },
    getScheduledTestsTable(params, then, error) {
        service.getRequest(1, 'scheduled-tests/table', params, then, error);
    },
    updateScheduledTest(testCase, then, error) {
        testCase.history.modifyDate = Math.floor(Date.now() / 1000);
        service.patchRequest(1, '{id}/update-scheduled-test', testCase, then, error, testCase.id);
    },
    deleteScheduledTest(id, then, error) {
        service.deleteRequest(1, '{id}/delete-scheduled-test', null, then, error, id);
    },

    toggleScheduledTest(id, control, then, error) {
        if (control !== '0' && control !== '1') {
            control = '0';
        }
        service.patchRequest(1, '{id}/toggle-scheduled-test', control, then, error, id);
    },
    getStencils(then, error) {
        service.getRequest(1, 'stencils', null, then, error);
    },
    stopTests(ids, then, error) {
        service.deleteRequest(1, 'stop-tests', {ids: ids.join(',')}, then, error);
    },
    isExplorerTestCase(id, then, error) {
        service.getRequest(1, '{id}/is-explorer-test-case', null, then, error, id);
    },

  startTests({ queueIds, groupId }) {
    const params = {
        queueIds,
    }

    if (groupId) {
      params.groupId = groupId;
    }

    return new Promise((resolve, reject) => {
        service.putRequest(1, 'start-tests', params, resolve, reject);
    })
  }

};

export default testCaseInfoService;
