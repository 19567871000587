import language from '@/locales/en.json';
import protocolsMapping from '@/cfg/protocolsMapping.json';
import packetAttributeTranslation from '@/cfg/packetAttributeTranslation.json';
import options from "@/cfg/options.json";
import requests from '@/js/requests';
import fiberNet from '@/js/fiber-net';

let mixin = {
    data: function () {
        return {
            language: language,
            functionName: '', // router: router,
        };
    },
    methods: {
        isset: object => {
            if (typeof object != 'undefined') {
                return true;
            } else {
                return false;
            }
        },
        empty: object => {
            if (typeof object == 'undefined' || object == '') {
                return true;
            } else {
                return false;
            }
        },
        safeClick(link) {
            let caller = this;
            if (caller.$route.path == link) {
                caller.$router.go();
            } else {
                let redirectLink = link;
                if (redirectLink.includes('?')) {
                    let saveLifeString = Math.random().toString(36).substring(7);
                    redirectLink += '&' + saveLifeString;
                }
                caller.$router.push(redirectLink).catch(() => {
                    caller.$router.go();
                });
            }
        },
        ajaxSubmit: function (functionName, form, url, callerData, uploadedFiles, parent, then) {
            var bodyFormData = new FormData(form);
            // console.log(JSON.stringify(bodyFormData), this.$store.rootState);
            // this.functionName= this.$store.rootState.functionName;
            // console.log(this.functionName);
            // bodyFormData.set('function',this.functionName);
            //hard coded for now
            bodyFormData.set('function', functionName);
            bodyFormData.set('submitType', 'post');

            if (typeof uploadedFiles != 'undefined') {
                for (let [fileName, file] of Object.entries(uploadedFiles)) {
                    console.log('appending', fileName, file);
                    bodyFormData.append(fileName, file);
                }
            }

            let additionalSubmitData = this.$store.state.additionalSubmitData;
            for (let [index, value] of Object.entries(additionalSubmitData)) {
                if (typeof value !== 'string') value = JSON.stringify(value);
                bodyFormData.set(index, value);
            }
            let caller = this;
            let path;
            if (typeof url == 'undefined' || url == '') {
                path = this.$route.fullPath;
                path = this.$route.fullPath.replace('/', 'serve.php');
            } else {
                path = url;
            }

            var jsonFormData = {};
            bodyFormData.forEach((value, key) => jsonFormData[key] = value);
            // console.log(path);
            requests.frameworkAxiosRequest({
                method: 'post',
                url: path,
                data: bodyFormData, /*  headers: {
                    "Content-Type": "multipart/form-data"
                  }*/
            })
                .then(function (response) {
                    if (then) {
                        then(response);
                        return;
                    }
                    //handle success
                    //console.log(response)
                    if (response.headers['content-type'] == 'application/octet-stream') {
                        caller.forceFileDownload(response);
                    } else {
                        caller.$set(response.data, 'jsonFormData', jsonFormData);
                        caller.$set(response.data.result, 'dialogRef', callerData.dialogRef);
                        caller.$set(response.data, 'functionName', functionName);
                        caller.$store.commit('dataSubmitted', response);
                        if (typeof response.data.result != 'undefined') {
                            if (typeof response.data.result.redirect != 'undefined') {
                                caller.$store.commit('closeDialog', callerData.dialogRef);
                                // console.log(caller.$route.path, response.data.result.redirect);
                                caller.safeClick(response.data.result.redirect);
                                // if (caller.$route.path == response.data.result.redirect) {
                                //   caller.$router.go();
                                // } else {
                                //   //redundant url Buggy!
                                //   // console.log("Redirect-->", response.data.result.redirect);
                                //   let redirectLink = response.data.result.redirect;
                                //   if (redirectLink.includes('?')) {
                                //     let saveLifeString = Math.random().toString(36).substring(7);
                                //     redirectLink += "&" + saveLifeString;
                                //   }
                                //   caller.$router.push(redirectLink).catch(() => {
                                //     caller.$router.go();
                                //   }).then(() => {
                                //     history.pushState({}, null, response.data.result.redirect);
                                //   });
                                // }
                                // caller.fetchData(response.data.result.redirect);
                            } else if (typeof response.data.result.download != 'undefined') {
                                window.location.href = response.data.result.download;
                                // axios({
                                //     method: 'get',
                                //     url: response.data.result.download,
                                //     responseType: 'arraybuffer'
                                //   })
                                //   .then(response => {

                                //     this.forceFileDownload(response);

                                //   })
                                //   .catch(() => console.log('error occured'));

                            } else if (response.data.result.content.includes('addReturn')) {
                                //  this.updateTable
                                if (typeof response.data.result.json != 'undefined' &&
                                    response.data.result.json.result == 'error') {
                                    caller.$set(response.data.result, 'dialogRef', callerData.dialogRef);
                                    caller.$store.commit('openResultDialog', response.data.result);
                                } else if (typeof response.data.result.replaceTable != 'undefined' &&
                                    response.data.result.replaceTable == 'true') {
                                    caller.replaceTable(response.data.result.table, callerData.dialogRef);
                                } else if (typeof response.data.result.updateTable != 'undefined' &&
                                    response.data.result.updateTable == 'true') {
                                    caller.updateTable(response.data.result.table, callerData.dialogRef,
                                        response.data.result.table);
                                } else {
                                    console.log(caller.result.dialogRef);
                                    caller.$store.commit('closeDialog', callerData.dialogRef);
                                }
                                if (typeof response.data.result.updatedFields != 'undefined') {
                                    caller.updateFields(response.data.result.updatedFields, parent);

                                }
                            } else {
                                let formContent = ['form', 'login', 'registration', 'myAccount'];
                                let found = response.data.result.content.some(r => formContent.includes(r));
                                if (found) {
                                    // if (response.data.result.content.includes("form") || response.data.result.content.includes("login") || response.data.result.content.includes("registration")) {
                                    if (typeof response.data.result.changePath != 'undefined') {
                                        history.pushState({}, null, response.data.result.changePath);
                                    }
                                    fiberNet.replace(response.data);
                                    caller.$store.commit('pagePostChanged', response);
                                    //caller.$store.commit("resultChanged", response.data.result);
                                } else {
                                    if (!response.data.result.content.includes('json')) {
                                        caller.$set(response.data.result, 'dialogRef', callerData.dialogRef);
                                        caller.$store.commit('dialogResultChanged', response.data.result);
                                        //if its snackbar | Notice should be close to show them!
                                        //console.log(response.data.result);
                                        if (response.data.result.content.includes('snackbar') ||
                                            response.data.result.content.includes('notice')) {
                                            let delay = 3000;
                                            if (typeof response.data.result.delay != 'undefined') {
                                                delay = response.data.result.delay;
                                            }
                                            setTimeout(function () {
                                                caller.$store.commit('closeDialog', callerData.dialogRef);
                                            }, delay);
                                        }
                                    } else {
                                        caller.$set(response.data.result, 'dialogRef', callerData.dialogRef);
                                        caller.$store.commit('submittedResultChanged', response.data.result);

                                        caller.$store.commit('closeDialog', callerData.dialogRef);
                                    }
                                }
                            }
                        } else {
                            console.log(response.data);
                            caller.$set(callerData, 'content', ['string']);
                            callerData.string = response.data;
                            console.log(callerData);
                            let data = {
                                result: callerData,
                            };
                            caller.$store.commit('dialogResultChanged', data.result);
                        }
                    }
                    caller.$store.commit('dialogButtonsLoading', false);
                })
                .catch(function (response) {
                    //handle error
                    console.log(response);

                });
        },
        forceFileDownload(response) {
            //console.log(new Blob([response.data]));
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'explorer-image.gz'); //or any other extension
            // link.setAttribute('download', 'file.pdf'); //or any other extension
            document.body.appendChild(link);
            link.click();
        },
        updateFields(updatedFields, caller) {
            for (let [ref, info] of Object.entries(updatedFields)) {
                if (typeof this.$store.state.dialogDataObject[ref] != 'undefined') {
                    let result = this.$store.state.dialogDataObject[ref].result;
                    for (let [field, fieldInfo] of Object.entries(info)) {
                        console.log('TYPE-->', result['fields'][field]);
                        if (typeof result['fields'][field] != 'undefined') {
                            this.$set(fieldInfo, 'type', result['fields'][field].type);
                            for (let [key, value] of Object.entries(fieldInfo)) {
                                //dont update type
                                //if (key != "type") {
                                this.$set(result['fields'][field], key, value);
                                // }
                            }
                            this.$set(result['valueLine'], field, fieldInfo.value);
                        }
                    }
                    this.$store.commit('dialogResultChanged', result);
                }
                console.log('emitting', info);
                caller.$emit('updated-fields', info);

            }

        },
        updateTable(table, dialogRef, result) {

            // let tablesData = {
            //   ...this.tablesData
            // };
            // tablesData[table.id] = table;
            //  this.$set(tablesData, table.id, table);
            //  this.$set(this, "tablesData", tablesData);
            let indexRow = 'n_id';
            if (typeof result != 'undefined' && typeof indexRow != 'undefined' && typeof result.indexRow !=
                'undefined') {
                indexRow = result.indexRow;
            }
            let storedTable = this.$store.state.tablesData[table.id];

            if (typeof storedTable != 'undefined') {
                let currentTable = {
                    ...this.$store.state.tablesData[table.id],
                };
                if (typeof currentTable.data == 'undefined') {
                    this.$set(currentTable, 'data', []);
                }
                if (typeof currentTable.header == 'undefined') {
                    this.$set(currentTable, 'header', table.header);
                }
                table.data.forEach((addedElement) => {
                    let index = -1;
                    currentTable.data.forEach((element, i) => {
                        if (element[indexRow] == addedElement[indexRow]) {
                            index = i;
                            return;
                        }
                    });
                    if (index == -1) {
                        currentTable.data.push(addedElement);
                    } else {
                        this.$set(currentTable.data, index, addedElement);
                    }
                });
                this.$store.commit('tableChanged', currentTable);
            } else {
                this.$store.commit('tableChanged', table);
            }
            this.$store.commit('closeDialog', dialogRef);
        },
        replaceTable(table, dialogRef) {

            let storedTable = this.$store.state.tablesData[table.id];
            // console.log(storedTable)
            if (typeof storedTable != 'undefined') {
                let currentTable = {
                    ...this.$store.state.tablesData[table.id],
                };

                this.$set(currentTable, 'data', table.data);

                if (typeof currentTable.header == 'undefined') {
                    this.$set(currentTable, 'header', table.header);
                }
                this.$store.commit('tableChanged', currentTable);
            } else {
                this.$store.commit('tableChanged', table);
            }
            this.$store.commit('closeDialog', dialogRef);
        },
        fetchData(sentPath, callbackFunction) {
            //console.log("fetch data");
            this.error = this.post = null;
            this.loading = true;
            let caller = this;
            let path = window.location.href;

            if (typeof sentPath != 'undefined' && sentPath !== '') {
                path = sentPath;
                //this.$route.fullPath=path;
                this.$router.push(path);

                history.pushState({}, null, path);
            } else if (this.$route.fullPath !== '/') {
                path = this.$route.fullPath;
                //this.$router.push(path);
                //history.pushState({}, null, path);
            }
            // console.log(path, path.split("/")[1]);
            // if (path.split("/")[1] == "") {
            //   path = "serve.php";
            //   history.pushState({}, null, path);
            // }
            path = this.$route.fullPath;
            if (!path.includes('.php')) {
                path = path.replace('/', 'serve.php');
            }

            requests.frameworkAxiosRequest({
                method: 'get',
                url: path,
            }).then((response) => {
                if (response && typeof response === 'object' && 'data' in response) {
                    if (typeof response.data.redirect != 'undefined') {
                        caller.$router.push(response.data.redirect).catch(() => {
                            caller.$router.go();
                        });
                    } else if (typeof response.data.result != 'undefined' && typeof response.data.result.redirect !=
                        'undefined') {
                        caller.$router.push(response.data.result.redirect).catch(() => {
                            caller.$router.go();
                        });
                    } else {
                        fiberNet.replace(response.data);
                        caller.$store.commit('pagePostChanged', response);
                    }
                }
                caller.loading = false;
                if (typeof callbackFunction == 'function') {
                    callbackFunction();
                }
            }).catch((error) => {
                console.log(path);
                console.log(error);
            });
        },
        getName: function (name) {
            if (typeof this.language[name] != 'undefined') {
                name = this.language[name];
            }
            return name;
        },
        languagePack: function (module, subModule = '') {
            //console.log(this.language)
            const obj = JSON.parse(JSON.stringify(this.language));
            let data;
            if (typeof module != 'undefined' && typeof obj[module] != 'undefined') {
                data = obj[module];
                if (typeof subModule != 'undefined' && subModule != '') {
                    if (typeof data[subModule] != 'undefined') {
                        return data[subModule];
                    } else {
                        return this.getLanguageTitle(module, subModule, data);
                    }
                } else {
                    if (typeof subModule != 'undefined' && subModule != '') {
                        return this.getLanguageTitle(module, subModule, data);
                    } else {
                        return data;
                    }
                }
            } else {
                return module;
            }
        },
        languageHelpProvider(fields, page) {
            /*if ( $page === false ) {
              $page = get_class ($this);
            }*/

            for (const [key] of Object.entries(fields)) {

                fields[key]['name'] = this.languagePack(page, key);
                let type = 'text';
                if (this.isset(fields[key]['type'])) {
                    // throw "type for " + key + " is not set in the fields array";
                    type = fields[key]['type'];
                }
                if (type == 'line' || type == 'switchGroup') {
                    fields[key].fields = this.languageHelpProvider(fields[key].fields, page);
                }
                // placeHolder = placeholder(page, key);
                // if (!empty($placeHolder)) {
                //   $fields[$key]['fieldAttrInput']['placeholder'] = placeholder($page, $key);
                // }
                // if ( isset($fields[$key]['addingURL']) ) {
                //   $fields[$key]['fieldAttrAddingOption']['help-data'] = help ($page, $key . "_addedOption");
                // }
            }
            return fields;
        },
        getLanguageTitle: function (module, subModule = '', data) {
            if (subModule == 'addTitle') {
                if (typeof data['menu_' + module] != 'undefined') {
                    return 'Add ' + data['menu_' + module];
                } else {
                    return 'Add ' + 'menu_' + module;
                }
            } else if (subModule == 'editTitle') {
                if (typeof data['menu_' + module] != 'undefined') {
                    return 'Edit ' + data['menu_' + module];
                } else {
                    return 'Edit ' + 'menu_' + module;
                }
            } else {
                return module + ' ' + subModule;
            }
        },
        getMappedName(value) {
            let name = value;
            if (typeof value != 'object' && typeof protocolsMapping[value] != 'undefined') {
                    name = protocolsMapping[value];
            }
            return name;
        },
        attributeTranslations: function (step, attribute) {
           // console.log(attribute);
            let result = attribute;
            const obj = JSON.parse(JSON.stringify(packetAttributeTranslation));
            let data;
            if (typeof step != 'undefined' && typeof obj[step] != 'undefined') {
                data = obj[step];
                if (typeof attribute!= 'undefined' && attribute != '') {
                    if (typeof data[attribute] != 'undefined') {
                        result =  data[attribute];
                    }
                }
            }
             return result;
        },
        getMappedNameTemplate(value) {
            let name = value;
            if (typeof value != 'object' && typeof protocolsMapping[value] != 'undefined') {
                name = protocolsMapping[value];
            }
            if (typeof this.$route.query.p != 'undefined' && this.$route.query.p == 'Protocols') {
                if (value == 'RADIUS') {
                    name = name + ' (recommended)';
                } else if (value == 'RADIUS_ACCOUNTING' || value == 'RADIUS_ACCESS') {
                    name = name + ' (legacy)';
                }
            }
            return name;
        },
        sendTeamEmail: function (source, className, functionName) { //todo: make it common function for mailing!
            var options = {
                'function': 'callHelpDoc',
                'requestType': 'ajax',
                'className': className,
                'functionName': functionName,
                'source': source,
            };
            requests.frameworkAxiosRequest({
                method: 'POST',
                url: 'serve.php?f=global&f2=emailServer',
                responseType: 'json',
                data: options,
            })
                .then(response => {
                    console.log(response);
                })
                .catch(error => {
                    console.log(error);
                });
        },
        showError(err) {
            // Ignore the vuex err regarding  navigating to the page they are already on.
            if (!err.message.includes('Avoided redundant navigation to current location')) {
                // But print any other errors to the console
                console.log(err);
            }
        },
        getFilteredRow: function (control, caller) {
            if (typeof control == 'undefined') {
                control = {
                    pre: false,
                    post: false,
                    condition: true,
                };
            }
            //console.log(control);
            this.$emit('input', control);
            var excludeRows = []; //need to know
            // let caller = this;
            requests.frameworkAxiosRequest({
                method: 'POST',
                url: 'serve.php?f=testing&f2=testInfo&function=getInfoUpdate',
                dataType: 'json',
                data: {
                    n_id: this.$route.query.n_id,
                    n_id_key: this.$route.query.n_id_key,
                    requestType: 'ajax',
                    excludeRows: excludeRows,
                    pre: control.pre,
                    post: control.post,
                    condition: control.condition,
                },
            })
                .then(function (response) {
                    // console.log(response.data.result.table);

                    caller.$set(response.data.result.table, 'sortBy', caller.result.table.sortBy);
                    caller.$set(response.data.result.table, 'sortDesc', caller.result.table.sortDesc);
                    caller.$store.commit('tableChanged', response.data.result.table);
                })
                .catch(function (response) {
                    console.log(response);
                });
        },
        getAttributeMapping(root,key){
            let mapped = key;
            if(typeof options[root].find(a => a[0] == key)!=='undefined' &&  this.isset(options[root].find(a => a[0] == key))){
                mapped = options[root].find(a => a[0] == key)[1];
            }
           return mapped;
        },
        getGroupAttributeMapping(root,key){
            let mapped = key;
            let opts= options[root];
            let groupName='';
            opts.forEach(function (element) {
                if(typeof element.header!=='undefined' ){
                    groupName = element.header;
                }
                if(typeof element.header==='undefined' && typeof element.divider==='undefined' && element.value== key){
                    mapped = element.text;
                    if(groupName!=''){
                        mapped = element.text+' ['+groupName+']';
                    }
                }
            });
            return mapped;
        },
        checkStencil(result){
            let stencil = false;
            if((this.$route.query.f2=="configureAndRunTests") &&
                (typeof result!="undefined" &&
                    typeof result.valueLine!="undefined" &&
                    typeof result.valueLine.c_type!="undefined" &&
                    result.valueLine.c_type=="stencil")
            ){
                 stencil = true;
            }
            return stencil;
        }

    },
};
export {
    mixin,
};

