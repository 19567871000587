<template>
  <div>
    <v-row no-gutters>
      <v-col v-for="(menuItem ,index)  in iterator" :key="index"
             :sm="menuItem.sm? menuItem.sm : Math.floor(12/Object.keys(iterator).length)" cols="12">
        <div v-if="typeof menuItem['type'] !='undefined'">
          <v-col v-if="menuItem['type']==='columnList'" v-bind="getDivattr(menuItem)">
            <div
                v-if="typeof menuItem['name'] !='undefined'"
                v-bind="menuItem['attr']"
                v-bind:class="classControl"
            >{{ language[menuItem["name"]] }} <template v-if="menuItem['name']=='menu_recentCompletedTests'">
              (as of {{currentTime}})
            </template> <!-- menu parent title -->
            </div>
            <div v-if="typeof menuItem['children'] !='undefined' && menuItem['name']=='menu_recentCompletedTests'">
              <menuList :submenuList="recentTestList" :disabled="false"></menuList>
            </div>
            <div
                v-else-if="typeof menuItem['children'] !='undefined'"
                v-bind="addAdditionalClassToAttributes(menuItem,'childrenContainerAttributes',  'childrenContainer')"
            >
              <menuList :submenuList="menuItem['children']" :disabled="false"></menuList>
            </div>
          </v-col>
          <v-col v-if="menuItem['type']==='column'" v-bind="getDivattr(menuItem)">
            <h3
                v-if="typeof menuItem['name'] !='undefined'"
                v-bind="menuItem['attr']"
            >{{ language[menuItem["name"]] }}</h3>
            <div v-if="typeof menuItem['component'] !='undefined'">
            <span>
              <input
                  type="search"
                  placeholder="Filter"
                  style="width: 250px;"
                  class="{menuItem['component']}"
                  onkeyup="searchAndHighlight(this,'menuItemListContainer div','megaMenu');"
              />
            </span>
              <div class="menuItemListContainer"></div>
            </div>
            <div
                v-if="typeof menuItem['children'] !='undefined'"
                v-bind="addAdditionalClassToAttributes(menuItem,'childrenContainerAttributes',  'childrenContainer')"
            >
              <menuOptions :iterator="menuItem['children']"></menuOptions>
            </div>
          </v-col>
          <div v-else-if="menuItem['type']==='container'" v-bind="getDivattr(menuItem)">
            <h3
                v-if="typeof menuItem['name'] !='undefined'"
                v-bind="menuItem['attr']"
            >{{ language[menuItem["name"]] }}</h3>
            <div
                v-if="typeof menuItem['children'] !='undefined'"
                v-bind="addAdditionalClassToAttributes(menuItem,'childrenContainerAttributes',  'childrenContainer')"
            >
              <menuOptions :iterator="menuItem['children']"></menuOptions>
            </div>
          </div>
          <h3
              v-else-if="menuItem['type']==='title'"
              v-bind="menuItem['attr']"
          >{{ language[menuItem["name"]] }}</h3>
          <cloud v-else-if="menuItem['type']==='cloud'" :submenuList="menuItem['children']"></cloud>
          <menuList v-else-if="menuItem['type']==='list'" :submenuList="menuItem['children']"></menuList>
          <div
              v-else-if="menuItem['type']==='line'"
              style="border-bottom: 1px solid rgba(128, 128, 128, 0.23);margin: 5px 0 15px;"
          ></div>
          <ocsTabs v-else-if="menuItem['type']==='ocsTabs'" :submenuList="menuItem['children']">
          </ocsTabs>
          <!-- <div v-else>
            <a v-bind="menuItem['attr']">{{language[menuItem["name"]] }}</a>

            <menuOptions
              v-if="typeof menuItem['children'] !='undefined'"
              :iterator="menuItem['children']"
            ></menuOptions>
          </div>-->
        </div>
        <div v-else>
          <div
              v-if="typeof menuItem['attr']['class'] !='undefined' && menuItem['attr']['class'] === 'buttEffectMenu'"
              :title="menuItem['title']"
              v-bind="getbuttEffectMenuattr(menuItem)"
          ><span class="catButtMenu">
            {{ language[menuItem["name"]] }}
            <span
                v-if="typeof menuItem['helpText'] !='undefined'"
                class="classicTip"
            >{{ menuItem['helpText'] }}</span>
          </span>
            <div
                v-if="typeof menuItem['notice'] !='undefined'"
                title="{menuItem['notice']['title']}"
                class="{menuItem['notice']['class']}"
            ></div>
            <i class="fas fa-chevron-right" style="color:#F7F7F7; "></i>
          </div>
          <div
              v-else
              :title="menuItem['title']"
              v-bind="addAdditionalClassToAttributes(menuItem,'divattr', 'dotted  menuItemContainer ')"
          ><a v-bind="menuItem['attr']">
            <span v-bind="menuItem['spanattr']">{{ language[menuItem["name"]] }}</span>
            <span
                v-if=" typeof menuItem['helpText'] !='undefined'"
                class="classicTip"
            >{{ menuItem['helpText'] }}</span>
          </a>
            <div
                v-if="typeof menuItem['notice'] !='undefined'"
                title="{menuItem['notice']['title']}"
                class="{menuItem['notice']['class']}"
            ></div>
          </div>
          <menuList
              v-if="typeof menuItem['children'] !='undefined'"
              :submenuList="menuItem['children']"
          ></menuList>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {addAdditionalClassToAttributes} from "@/js/helper.js";
import menuList from "@/commonComponents/menuList.vue";
import ocsTabs from "@/commonComponents/ocsTabs.vue";
//import menuOptions from "@/commonComponents/menuOptions.vue";
import moment from "moment";

export default {
  name: "menuOptions",
  components: {menuList, ocsTabs},
  props: ["iterator","recentTestList"],
  data: function () {
    return {
      classControl: "primary--text childMenuHeader",
      currentTime : moment().format('DD.MM HH:mm:ss'),
    };
  },
  mounted() {
    this.classControl = "primary--text childMenuHeader ml-4";
    if (Object.keys(this.iterator)[0] === "object") {
      this.classControl = "primary--text childMenuHeader mt-4 mb-3";
    }
    // for live time display!
    setInterval(this.currentTimeDisplay, 1000);
  },
  methods: {
    // this.$parent.$emit('menu-open', true); directly to parent!
   currentTimeDisplay(){
       return  this.currentTime = moment().format('DD.MM HH:mm:ss');
    },
    addAdditionalClassToAttributes: addAdditionalClassToAttributes,
    getDivattr: function (menuItem) {
      return addAdditionalClassToAttributes(menuItem, "divattr", "mega-col");
    },
    getbuttEffectMenuattr: function (menuItem) {
      //let catColor = "";
      let buttEffect;
      if (
          typeof menuItem["attr"]["disable-status"] != "undefined" &&
          menuItem["attr"]["disable-status"] === 1
      ) {
        buttEffect = "buttEffectGrey";
        // catColor = "activeGreyButt";
      } else {
        buttEffect = "buttEffectBlue";
        // catColor = "activeBlueButt";
      }
      let activeCatClass = "";
      // if (
      //   typeof $session["activeCat"] != "undefined" &&
      //   $session["activeCat"] &&
      //   language(menuItem["name"])
      // ) {
      //   activeCatClass = catColor;
      // }

      let attrs = addAdditionalClassToAttributes(
          menuItem,
          "divattr",
          " menuItemContainer   categoryItems activeCatClass " +
          buttEffect +
          " " +
          activeCatClass
      );

      if (typeof menuItem["attr"] != "undefined") {
        attrs = {...attrs, ...menuItem["attr"]};
      }
      return attrs;
    }
  }
};
</script>

