<template>
  <div>
    <XDialog
        :buttons="!$scopedSlots['dialog-bottom'] ? buttons : undefined"
        :persistent="modified"
        :title="computedTitle"
        :value="value"
        :width="width"
        :height="height"
        :unpadded="unpadded"
        maximizable
        always-show-scrollbar
        @input="closeDialog"
        @click:outside="closeDialog">
      <template #dialog-content="{ element, handleScrollRequest }">
        <v-form ref="form" v-model="valid" :disabled="disabled">
          <FormDialogContent :disabled="disabled">
            <slot name="dialog-content" :valid="valid" :element="element" :handle-scroll-request="handleScrollRequest"/>
          </FormDialogContent>
        </v-form>
      </template>
      <template #dialog-bottom>
        <slot name="dialog-bottom" :save="save" :close="closeDialog" :valid="combinedValid"/>
      </template>
    </XDialog>
    <YesNoDialog
        v-model="unsavedChangesDialog"
        text="You have unsaved changes."
        title="Really close?"
        width="310"
        yes-icon="mdi-close"
        @yes="$emit('input', false)"/>
  </div>
</template>

<script>
import XDialog from '@/components/basic/XDialog';
import YesNoDialog from '@/components/extended/YesNoDialog';
import FormDialogContent from '@/components/extended/FormDialogContent.vue';
import {deepCopy, deepEquals} from '@/js/general';

export default {
  name: 'FormDialog',
  components: {
    FormDialogContent,
    YesNoDialog,
    XDialog,
  },
  props: {
    value: Boolean,
    title: String,
    item: Object,
    itemId: Number,
    itemName: String,
    width: [Number, String],
    height: [Number, String],
    customValid: {
      type: Boolean,
      default: true,
    },
    disabled: Boolean,
    unpadded: Boolean,
    saveText: {
      type: String,
      default: 'Save',
    },
    saveIcon: {
      type: String,
      default: 'mdi-content-save',
    },
    saveColor: {
      type: String,
      default: 'save',
    },
  },
  data() {
    return {
      buttons: [
        {
          text: this.saveText,
          icon: this.saveIcon,
          color: this.saveColor,
          textColor: 'white',
          disabled: true,
          click: this.save,
        },
        {
          text: 'Cancel',
          icon: 'mdi-cancel',
          color: 'secondary',
          textColor: 'white',
          click: this.closeDialog,
        },
      ],
      valid: false,
      error: false,
      unsavedChangesDialog: false,
      waitingForInput: false,
      unmodifiedItem: null,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value) {
          this.validateIfInitialized();
          this.$emit('open');
          this.unmodifiedItem = deepCopy(this.item);
          this.reload();
        } else {
          this.$emit('close');
        }
      },
    },
    item: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          const form = this.$refs.form;
          if (form) form.validate();
        });
      },
    },
    valid() {
      this.buttons[0].disabled = !this.combinedValid;
    },
    customValid() {
      this.buttons[0].disabled = !this.combinedValid;
    },
  },
  computed: {
    saveDisabled() {
      return this.loading || !this.valid || !!this.error;
    },
    computedTitle() {
      if (this.title) return this.title;
      if (this.itemName) {
        if (!this.itemId) return `New ${this.itemName}`;
        if ('name' in this.item) return `Edit ${this.itemName}: ${this.item.name}`;
        return `Edit ${this.itemName}`;
      }
      return '';
    },
    combinedValid() {
      return this.valid && this.customValid;
    },
    modified() {
      return !deepEquals(this.item, this.unmodifiedItem);
    },
  },
  methods: {
    reload() {
      if (this.itemId === 0 || this.itemId === undefined) {
        this.error = '';
        this.$emit('reload', null);
        this.$nextTick(() => {
          this.$refs.form.validate();
        });
      }
    },
    closeDialog() {
      if (!this.modified) {
        this.$emit('input', false);
      } else {
        this.unsavedChangesDialog = true;
      }
    },
    save(...args) {
      this.$emit('save', ...args);
    },
    validateIfInitialized() {
      if (this.$refs.form) {
        this.$refs.form.validate();
      } else {
        this.$nextTick(this.validateIfInitialized);
      }
    },
  },
};
</script>

<style scoped>

</style>