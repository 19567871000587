<template>
  <div
    v-if="features.useOnMobile || !$vuetify.breakpoint.mobile"
    class="d-flex"
  >
    <dynamicComponent
      v-if="typeof menuItem.template != 'undefined'"
      :key="menuItem.template"
      :type="menuItem.template"
      :result="{}"
      :additional="{ row: menuItem }"
    ></dynamicComponent>
    <div v-if="this.features.granted !== 'invisible'">
      <div
        v-if="
          menuItem.attr &&
          menuItem.attr.href &&
          disabled !== true &&
          this.features.granted === 'active'
        "
        style="margin-top: 2px"
      >
        <router-link :to="menuItem.attr.href" v-if="!checkSameCondition">
          <!-- <a> -->
          <slot>
            <span :class="dotted ? 'dotted' :''" :id="id">
              {{ getName(menuItem["name"]) }}
            </span>
          </slot>

          <!-- </a> -->
        </router-link>
        <a :href="menuItem.attr.href" v-else @click.stop="checkSameLink">
          <slot>
            <span :class="dotted ? 'dotted' :''" :id="id">
              {{ getName(menuItem["name"]) }}
            </span>
          </slot>
        </a>
       <label style="position: absolute;top:1px;"
              title="New Feature!"
              class="green--text"
              v-if="typeof menuItem.features!='undefined' &&
                    typeof menuItem.features.showBeta!='undefined' &&
                    menuItem.features.showBeta===true &&
                    checkDate">Beta</label>
      </div>
      <div
        v-else-if="
          this.features.granted === 'inactive' &&
          typeof this.features.inactiveLink != 'undefined'
        "
        style="margin-top: 2px"
      >
        <a :href="this.features.inactiveLink" class="grey--text text--darken-1">
          <slot>
            <span :class="dotted ? 'dotted' :''" :id="id">
              <!--DEG-2653 -->
              <v-tooltip
                top
                max-width="400"
                v-if="typeof features.inactiveTooltip != 'undefined'"
              >
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    {{ getName(menuItem["name"]) }}</span
                  >
                </template>
                <span>{{ features.inactiveTooltip }}</span>
              </v-tooltip>
            </span>
          </slot>
        </a>
      </div>
      <div v-else color="gray" :class="dotted ? 'dotted' :''" :id="id">
        {{ getName(menuItem["name"]) }}
      </div>
    </div>
    <div>
      <menuFeatureIcon :menuItem="menuItem"></menuFeatureIcon>
    </div>
  </div>
</template>
<script>
import menuFeatureIcon from "@/commonComponents/menuFeatureIcon.vue";
import dynamicComponent from "@/commonComponents/dynamicComponent.vue";
import moment from "moment";

export default {
  components: { menuFeatureIcon, dynamicComponent },
  props: ["menuItem", "disabled", "index"],
  computed: {
    checkDate(){
        let result = false;
        if(typeof this.menuItem.features.showDate!='undefined' && typeof this.menuItem.features.dayCounts!='undefined'){
          const start = moment(this.menuItem.features.showDate);
          const end =  moment();
          const diff = end.diff(start, "days")
          if(diff <=this.menuItem.features.dayCounts) { //show until daycounts
              result=true;
            }
        }
       return result;
    },
    dotted() {
      let dotted = true;
      if (typeof this.menuItem.dotted!="undefined" ){
        dotted= this.menuItem.dotted
      }
      return dotted;
    },
    id() {
      return this.index + "menu_item";
    },
    checkSameCondition() {
      let path = this.$route.fullPath;
      path = path.replace("/", "");
      return path === this.menuItem.attr.href;
    },
    features() {
      let features = { granted: "active" };

      if (typeof this.menuItem.features != "undefined") {
        features = this.menuItem.features;
      }

      return features;
    },
  },
  methods: {
    getName: function (name) {
      if (typeof this.language[name] != "undefined") {
        name = this.language[name];
      }
      return name;
    },

    checkSameLink(event) {
      let path = this.$route.fullPath;
      path = path.replace("/", "");
      if (path === this.menuItem.attr.href) {
        event.preventDefault();
        let saveLifeString = Math.random().toString(36).substring(7);
        this.$router
          .push(this.menuItem.attr.href + "&" + saveLifeString)
          .catch(() => {
            this.$router.go();
          })
          .then(() => {
            history.pushState({}, null, this.menuItem.attr.href);
          });
      }
      // else {
      //   this.$router.push(this.menuItem.attr.href);
      // }
    },
    openInNewTab() {
      console.log("middle");
      window.open(this.menuItem.attr.href, "_blank");
    },
  },
};
</script>

<style scoped>
.dotted {
  display: inline-block;
  max-width: 385px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.dotted:hover:before {
  color: #2b5593;
}

.dotted:before {
  content: "\2022";
  position: relative;
  left: unset;
  padding-right: 3px;
  color: #9e9e9e6b;
  font-size: 28px;
  vertical-align: middle;
}
</style>

