var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.cleanedIterator),function(mainMenuItem,index){return _c('v-menu',{key:index,attrs:{"close-on-content-click":false,"open-on-hover":"","bottom":"","offset-y":"","close-delay":200},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(typeof mainMenuItem['type'] != 'undefined' &&
                mainMenuItem['type'] === 'upperLink')?[(typeof mainMenuItem['features'].granted != 'undefined' &&
                mainMenuItem['features'].granted === 'active')?_c('router-link',{attrs:{"to":mainMenuItem.attr.href}},[_c('v-btn',{attrs:{"depressed":"","color":"primary","icon":_vm.$vuetify.breakpoint.xs,"id":index+'_main_menu',"x-small":_vm.$vuetify.breakpoint.smAndDown}},[(!_vm.$vuetify.breakpoint.xs)?[_vm._v(_vm._s(_vm.language[mainMenuItem.name]))]:_vm._e()],2)],1):_vm._e()]:[_c('v-btn',_vm._g({attrs:{"x-small":_vm.$vuetify.breakpoint.smAndDown,"color":"primary","depressed":"","icon":_vm.$vuetify.breakpoint.xs,"id":index+'_main_menu'}},on),[(_vm.$vuetify.breakpoint.xs)?_c('v-icon',{attrs:{"color":"white"}},[_vm._v(_vm._s(mainMenuItem.icon)+" ")]):_vm._e(),(!_vm.$vuetify.breakpoint.xs)?[_vm._v(_vm._s(_vm.language[mainMenuItem.name]))]:_vm._e()],2)]]}}],null,true),model:{value:(_vm.open[index]),callback:function ($$v) {_vm.$set(_vm.open, index, $$v)},expression:"open[index]"}},[(typeof mainMenuItem['type'] == 'undefined' ||
                mainMenuItem['type'] !== 'upperLink' )?_c('v-card',[_c('v-list',[_c('v-list-item',[_c('div',{staticClass:"megaMenu"},[(
                typeof mainMenuItem['type'] != 'undefined' &&
                mainMenuItem['type'] === 'layers'
              )?_c('div',[_c('v-tabs',{attrs:{"right":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_vm._l((mainMenuItem[
                    'children'
                  ]),function(menuItem,tabIndex,count){return _c('v-tab',{key:tabIndex,on:{"mouseover":function($event){return _vm.changeTab(count)},"mouseout":function($event){return _vm.resetTab(count)}}},[_vm._v(_vm._s(_vm.language[menuItem.name])+" ")])}),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((mainMenuItem['children']),function(menuItem,tabIndex){return _c('v-tab-item',{key:tabIndex},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[(typeof menuItem['children'] != 'undefined')?_c('megaMenu',{attrs:{"submenuList":menuItem['children'],"enableTabs":_vm.getEnableTabs(mainMenuItem['children']),"mainMenuItem":mainMenuItem,"menuItem":menuItem}}):_vm._e()],1)],1)],1)}),1)],2)],1):_c('div',[_c('megaMenu',{attrs:{"recentTestList":_vm.recentTestList,"submenuList":mainMenuItem['children'],"enableTabs":false,"tabIndex":0,"mainMenuItem":mainMenuItem,"menuItem":{}}})],1)])])],1)],1):_vm._e()],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }