<template>
  <div>
    <!-- <v-progress-linear v-show="currentProgress<100" v-model="currentProgress" color="red"></v-progress-linear> -->
    <v-system-bar v-show="currentProgress==100" class="upperHeader white--text" color="upperHeader" height="4"></v-system-bar>
    <v-progress-linear v-show="currentProgress<100" v-model="currentProgress" color="upperHeader"></v-progress-linear>
     <v-overlay :value="currentProgress<100" z-index="99999" class="mt-1"></v-overlay>
  </div>
</template>
<script>
export default {
  created: function() {
    this.initProgress(this.$router);
  },
  data: function() {
    return {
      progressShowDelay: 100,
      routeResolved: false,
      currentProgress: 100
    };
  },

  watch: {
    loading: function(newVal) {
     // console.log("value chaged", newVal);
      if (newVal == true) {
        this.tryInitProgress();
      } else {
        this.cancelTopProgress();
      }
    }
  },
  methods: {
    /**
     * Initializes NProgress after a specified delay only if the route was not resolved yet.
     */

    cancelTopProgress() {
      this.routeResolved = true;
      this.currentProgress = 100;
    },
    tryInitProgress() {
      this.routeResolved = false;
      this.currentProgress = 0;
      //console.log("before timeout ", this.routeResolved, this.currentProgress);
      this.startProgressing();
    },
    startProgressing() {
      setTimeout(
        function() {
          //console.log("progressing", this.routeResolved, this.currentProgress);
          if (!this.routeResolved && this.currentProgress < 99) {
            if (this.currentProgress < 50) {
              this.currentProgress = this.currentProgress + 5;
            } else if (this.currentProgress < 80) {
              this.currentProgress = this.currentProgress + 1;
            } else {
              this.currentProgress = this.currentProgress + 0.1;
            }

            this.startProgressing();
          }
        }.bind(this),
        this.progressShowDelay
      );
    },
    initProgress(router) {
     // console.log(router);
      router.afterEach(this.cancelTopProgress);
      router.beforeEach((to, from, next) => {
        this.tryInitProgress();
        return next();
      });
    }
  },
  props: ["loading"]
};
</script>
