<template>
  <v-app
    v-if="!publicContent"
    id="app-root"
    :key="currentHash"
    v-cloak
    :class="`${version >= 2 ? 'app-v2' : ''}`"
  >
    <DialogsRoot>
      <notifications
        group="foo"
        style="width: 36%; top: 50%; left: 32%; z-index: 9999; position: absolute"
        classes="my-custom"
      />

      <headerContent
        :userInfo="userInfo"
        :menu="menu"
        :result="result"
        :loading="loading"
        :small-header="smallHeader"
      ></headerContent>
      <!-- Sizes your content based upon application components -->
      <v-main>
        <div v-if="version < 2">
          <!-- Provides the application the proper gutter -->
          <v-container fluid>
            <!-- <editDialog /> -->
            <div
              id="loginWindow"
              title="Login"
              style="visibility: hidden"
            ></div>
            <div id="pageWarper">
              <notMobilePage
                v-if="
                  result != null &&
                  typeof result.features != 'undefined' &&
                  !result.features.useOnMobile &&
                  $vuetify.breakpoint.mobile
                "
              ></notMobilePage>

              <dynamicComponent
                v-else
                v-for="type in content"
                :key="type"
                :type="type"
                :result="result"
                :additional="{ userInfo: userInfo }"
              >
                {{ result }}
              </dynamicComponent>
            </div>
          </v-container>
        </div>

        <dynamicComponent
          v-else
          v-for="type in content"
          :key="type"
          :type="type"
          :result="result"
          :additional="{}"
          :class="setClassOnMainContent(version, type)"
        >
          {{ result }}
        </dynamicComponent>
      </v-main>

      <footerBlock
        :userInfo="userInfo"
        :result="result"
      ></footerBlock>
      <!-- <webapp></webapp> -->
      <div id="snackbar">
        <v-snackbar
          v-for="(notification, i) of $store.state.notifications"
          :key="notification.id"
          v-model="notification.text"
          :timeout="notification.length"
          :style="`margin-bottom: ${i * 60 + 30}px;`"
        >
          {{ notification.text }}
        </v-snackbar>
      </div>

      <div
        class="refresh-container"
        v-if="hashChanged && $root.env !== 'development'"
      >
        <div class="notification-header">
          <button
            type="button"
            class="close-refresh-modal"
            @click="closeModal"
            aria-label="Close"
          >
            <span aria-hidden="true"><i class="fal fa-times fa-sm"></i></span>
          </button>
        </div>

        <div class="notification-body">
          <div class="notification-button">
            <p class="text-center font12">
              An update is available. Please save all current work and click update below. You can
              also accept these updates by refreshing your browswer at any time.
            </p>
            <p class="text-center">
              <span class="font10">Not updating may result in errors.</span>
            </p>
          </div>

          <div class="refresh-button text-center">
            <button
              class="btn btn-default"
              @click="reloadApp"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </DialogsRoot>
  </v-app>

  <PublicContent v-else />
</template>

<script>
  import { defineComponent, provide, readonly, getCurrentInstance } from "vue"
  import { mapActions } from "vuex"
  import dynamicComponent from "@/commonComponents/dynamicComponent.vue"
  import headerContent from "@/commonComponents/headerContent.vue"
  import footerBlock from "@/components/legacy/footerBlock.vue"
  import notMobilePage from "@/components/legacy/notMobilePage.vue"
  import PublicContent from "@/components/specific/PublicContent"
  import { refreshPageMixin } from "@/mixins/refresh-page"
  import requests from "@/js/requests"
  import DialogsRoot from "@/components/specific/Dialogs/DialogsRoot.vue"
  import { useReactiveAuthFlag, tokenRefresher, doMigration as doTokenMigration, getAccessToken } from "@/auth-tools"
  import axios from "axios"
  import { useRouter, useRoute } from "vue-router/composables"
  import { authService } from "@/api"

  const IS_AUTH_PROVIDE = "IS_AUTH"

  export default defineComponent({
    components: {
      PublicContent,
      dynamicComponent,
      headerContent,
      footerBlock,
      notMobilePage,
      DialogsRoot,
    },

    mixins: [refreshPageMixin],

    setup() {
      const { isAuthenticated } = useReactiveAuthFlag()
      const router = useRouter()
      const route = useRoute()
      const vm = getCurrentInstance()

      tokenRefresher.subscribe("pre-logout", async () => {
        try {
          await authService.v1.logout(getAccessToken())
        } catch (err) {
          console.error(err)
        }
      });

      tokenRefresher.subscribe("post-logout", ({ isAuth }) => {
        if (!isAuth) {
          const store = vm.proxy.$store
          router.push({ ...route, query: { f: "logout" } })
          store.commit("resetState")
        }
      })

      provide(IS_AUTH_PROVIDE, {
        isAuth: readonly(isAuthenticated),
      })

      doTokenMigration(tokenRefresher)

      return {
        isAuth: isAuthenticated,
      }
    },

    data() {
      return {
        loading: false,
        post: null,
        error: null,
        content: [],
        result: null,
        menu: null,
        userInfo: null,
      }
    },

    beforeDestroy() {
      this.unsubscribe()
    },

    mounted() {
      window.addEventListener("orientationchange", this.handleOrientationChange)

      if (this.isAuth) {
        return this.fetchEssentialData()
      }
    },

    watch: {
      // call again the method if the route changes
      $route: "callFetchData",
      publicContent: {
        immediate: true,
        handler(value) {
          if (value) return
          this.loadApp()
        },
      },
    },

    computed: {
      smallHeader() {
        return this.result != null && "version" in this.result && this.version >= 2
      },

      publicContent() {
        return this.$route.fullPath.startsWith("/charts")
      },

      version() {
        if (this.result) return this.result.version
        return 1
      },
    },

    methods: {
      ...mapActions(["fetchEssentialData"]),

      setClassOnMainContent(version, type) {
        const EXPLUDED_PAGES = ["SimsView", "SIMHistoryView"]

        if (version >= 2 && !EXPLUDED_PAGES.includes(type)) {
          return "main-v2"
        }

        return ""
      },

      loadApp() {
        // fetch the data when the view is created and the data is
        // already being observed
        this.fetchData("", () => {})

        this.unsubscribe = this.$store.subscribe((mutation, state) => {
          if (mutation.type === "pageLoading") {
            this.loading = state.pageLoading
          } else if (mutation.type === "pagePostChanged") {
            // clearing the tables for a new page
            this.$store.state.tablesData = {}
            let post = state.pagePost
            this.post = post

            this.result = post.data.result
            this.content = []
            if (typeof this.result != "undefined") {
              this.content = this.result.content
            }
            this.userInfo = post.data.userInfo
            if (typeof post.data.menu != "undefined") {
              this.menu = post.data.menu
            }

            if (this.result != null) {
              document.title = this.result.pageTitle
            }
            if (
              (this.menu == null || this.menu.length === 0) &&
              typeof this.result != "undefined" &&
              this.result.disableMenu !== true
            ) {
              this.getMenu()
            }
            //this.menu = post.data.menu;
            //this.language = post.data.language;

            if (typeof this.result != "undefined" && typeof this.result.table != "undefined") {
              // this.tablesData[this.result.table.id] = this.result.table;
              this.$store.commit("tableChanged", this.result.table)
            }
          } else if (mutation.type === "resultChanged") {
            let result = state.result

            this.$set(this, "result", result)

            if (typeof this.result != "undefined") {
              this.content = this.result.content
            }

            if (requests.isPublicUrl(this.$route.fullPath)) {
              return
            }

            this.fetchEssentialData()
          }
        })

        if (requests.isPublicUrl(this.$route.fullPath)) return
      },
      handleOrientationChange() {
        const orientation = window.screen.orientation.type
        if (orientation === "portrait-primary") {
          // portrait mode
          this.$router.go()
        } else if (orientation === "landscape-primary") {
          // landscape mode
        }
      },
      async getMenu() {
        const resp = await axios.get("serve.php", { params: { f: "menu" }, withCredentials: true })
        if (!resp.data) {
          return
        }
        this.menu = resp.data
      },
      callFetchData() {
        this.fetchData(undefined, undefined)
      },
    },
  })
</script>

<style>
@import "./assets/styles/style.css";
:root {
  --app-header-height: 88px;
  --app-footer-height: 25px;
  --app-main-height: calc(100vh - var(--app-header-height) - var(--app-footer-height));
}

[v-cloak] {
  display: none;
}

/**
  * To avoid double scrollbars on the page
  * can be implemented gradually for every page via --css-variables on the :root (SimsView.vue as an example)
  */
#app-root > .v-application--wrap {
  display: var(--v-application-wrap-display, flex);
  grid-template-rows: var(--app-header-height) var(--app-main-height) var(--app-footer-height);
  grid-template-columns: 100%;
  overflow: var(--v-application-wrap-overflow, initial);
}

.main-v3 {
  display: flex;
  flex: 1 0 auto;
  max-width: 100%;
  overflow-y: auto;
  /**
    * if #app-root > .v-application--wrap display: grid, then you don't need to set max-height for .main-v3
  */
  max-height: var(--main-v3-max-height, calc(100% - 24px - 64px - 25px));
}

</style>

<style scoped>
  html {
    overflow-y: auto;
  }

  .app-v2 {
    display: flex;
    flex-direction: column;
    height: 100vh; /* occupy the full viewport height */
  }

  headerContent,
  footerBlock {
    flex-shrink: 0; /* don't shrink */
    flex-grow: 0; /* don't grow */
  }

  .main-v2 {
    display: flex;
    flex: 1 0 auto;
    max-width: 100%;
    overflow-y: auto;
    height: calc(100vh - 92px - 25px);
  }

.main-v3 >>> .v-main__wrap > *,
.main-v3 >>> .v-main__wrap > * > .x-data-table {
  height: 100%;
}

  .refresh-container {
    width: 15%;
    position: fixed;
    bottom: 10px;
    right: 15px;
    background-color: white;
    padding: 25px;
    -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.05);
  }

  .close-refresh-modal {
    position: absolute;
    right: 5px;
    top: 5px;
    border: none;
  }

  #snackbar {
    position: relative;
    z-index: 9999;
  }
</style>
