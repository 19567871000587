<template>
  <div class="explorer-test-results">
    <XDataTable
      :headers="headers"
      :items-request="getExplorerTestStatus"
      :items-request-params="itemsRequestParams"
      :refresh="30"
      :select-actions="selectActions"
      :height="680"
      :range-default-seconds="3600"
      @refresh="() => refreshStatusesCounts()"
      no-count
      :row-style="getRowStyle"
    >
      <template #time-range-slot>
        <DateTimeRange
          :value="range"
          @input="(newRange) => handleRangeUpdate(newRange)"
        />
      </template>

      <template #search-input-slot>
        <XTextField
          class="explorer-test-results__search-text-field"
          :value="search"
          clearable
          label="Search"
          append-icon="mdi-magnify"
          @input="(searchStr) => handleSearchUpdate(searchStr)"
        />
      </template>

      <template #above-table>
        <div class="explorer-test-results__status-bar">
          <XBtn
            class="explorer-test-results__total-button"
            :text="statusTotal.text"
            height="25"
            :color="statusesFilter.length ? `${statusTotal.color} lighten-3` : `${statusTotal.color}`"
            elevation="0"
            @click="() => selectTotalStatuses()"
          />

          <SplitButton
            class="explorer-test-results__statuses-split-btn"
            v-model="statusesFilter"
            :values="statusesCounts"
            :splits="statusesRepr"
            :total="statusesCounts?.total || 0"
            multiple
          />
        </div>
      </template>

      <template #[`item.testCase.name`]="{value, row}">
        <SearchRouterLink
            v-model="search"
            :text="value"
            :to="`?f=testing&f2=testInfo&function=getInfo&n_id=${row['id']}&n_id_key=${shaKey(row['id'])}`"
            @click="$emit('redirect')"
        />
      </template>

      <template #[`item.testInfo`]="{row}">
        <TestInfoButton :test-id="row.id" :test-id-key="shaKey(row.id)"/>
      </template>

      <template #[`item.status`]="{value}">
        <div class="explorer-test-results__status">
          <v-icon :color="testStatus[value].color">{{ testStatus[value].icon }}</v-icon>
          {{ testStatus[value].text }}
        </div>
      </template>

      <template #pagination-slot="{ metadata }">
        <PaginationBlock
          :total-count="metadata.totalCount || 0"
          :current-page="page"
          :items-per-page="metadata.perPage || 0"
          @update:current-page="(pageN) => setPageN(pageN)"
        />
      </template>

      <template #items-per-page-slot>
        <XSelect
          class="explorer-test-results__items-per-page-select"
          :items="ITEMS_PER_PAGE_OPTIONS_DEFAULT"
          :value="tableSettings.itemsPerPage"
          @input="(_itemsPerPage) => updateItemsPerPage(_itemsPerPage)"
          label="Items per page"
          required
          :autocomplete="false"
        />
      </template>
    </XDataTable>

    <LoadingDialog :value="loading"/>
  </div>
</template>

<script>
import { defineComponent, ref, onBeforeMount, computed } from "vue"
import {shaKey} from '@/js/helper.js';
import XDataTable from '@/components/basic/XDataTable.vue';
import XSelect from '@/components/basic/XSelect.vue';
import LoadingDialog from '@/components/basic/LoadingDialog.vue';
import testStatus from "@/cfg/testStatus.json";
import TestInfoButton from '@/components/specific/TestInfo/TestInfoButton.vue';
import SearchRouterLink from '@/components/basic/SearchRouterLink.vue';
import SplitButton from "@/components/basic/SplitButton.vue";
import PaginationBlock from "@/components/basic/tables/PaginationBlock.vue";
import DateTimeRange from '@/components/basic/DateTimeRange.vue';
import XTextField from '@/components/basic/XTextField.vue';
import XBtn from '@/components/basic/XBtn.vue';
import cockpitTestStatusService from "@/js/services/CockpitTestStatusService";
import testCaseInfoService from "@/js/services/TestCaseInfoService";
import { toColonDuration, unixToDateTimeString} from '@/js/general';
import requests from "@/js/requests";
import { useStatusBarSelect } from "@/composition/filtering-components/use-status-bar-select"
import { useTimeRangeSelect, HOUR_IN_SECONDS } from "@/composition/filtering-components/use-time-range-select"
import { useTableSettings, ITEMS_PER_PAGE_OPTIONS_DEFAULT } from "@/composition/tables/use-table-settings"
import { debounce } from "lodash-es"

const DEFAULT_RANGE = {
  seconds: HOUR_IN_SECONDS,
  from: new Date((Date.now() / 1000 - HOUR_IN_SECONDS) * 1000),
  to: new Date(),
}

const MIN_SEARCH_LENGTH = 3
const OLD_TABLE_SETTINGS_KEY = "explorerTestResults"
const TABLE_SETTINGS_KEY = "explorer-test-results-table"

// Termporary solution
// TODO: remove this function after using new table components
function getExplorerTestStatus(
  explorerId,
  status,
  currentProject,
  search,
  itemsPerPage,
  rangeFrom,
  rangeTo,
  page,
  params,
  then,
  error
) {
  params["search"] = search
  params["from"] = rangeFrom
  params["to"] = rangeTo
  params["items-per-page"] = itemsPerPage
  params["page"] = page
  cockpitTestStatusService.getExplorerTestStatus(explorerId, status, currentProject, params, then, error)
}

export default defineComponent({
  name: 'ExplorerTestResults',

  components: {
    SearchRouterLink,
    SplitButton,
    XBtn,
    TestInfoButton,
    LoadingDialog,
    XDataTable,
    DateTimeRange,
    XTextField,
    XSelect,
    PaginationBlock,
  },

  props: {
    explorerId: Number,
  },

  setup(props) {
    localStorage.removeItem(OLD_TABLE_SETTINGS_KEY)

    const {
      statusesCounts,
      statusesFilter,
      statusesRepr,
      statusTotal,
      statusesAsParamString,
      selectTotalStatuses,
    } = useStatusBarSelect({ multiple: true })
    const { range, rangeAsReqParams } = useTimeRangeSelect({ defaultRange: DEFAULT_RANGE })
    const search = ref("")
    const searchAsReqParam = computed(() => {
      if (search.value.length < MIN_SEARCH_LENGTH) {
        return ""
      }
      return search.value
    })
    const {
      settings: tableSettings,
      updateItemsPerPage,
    } = useTableSettings({ key: TABLE_SETTINGS_KEY, defaultSettings: { itemsPerPage: 25 } })
 
    const page = ref(1)
    const setPageN = pageN => {
      page.value = pageN
    }

    const getTestResultsStatusCounts = async () => {
      const params = {
        from: rangeAsReqParams.value.from,
        to: rangeAsReqParams.value.to,
        search: search.value,
      };

      try {
        return await cockpitTestStatusService.getExplorerTestStatusCounts(props.explorerId, false, params)
      } catch (error) {
        console.error("[getExplorerTestStatusCounts]", error)
      }
    }

    const refreshStatusesCounts = async () => {
      if (!props.explorerId) return

      statusesCounts.value = await getTestResultsStatusCounts()
    }

    onBeforeMount(async () => {
      await refreshStatusesCounts()
    })

    return {
      page,
      setPageN,
      statusesCounts,
      statusesFilter,
      selectTotalStatuses,
      statusesRepr,
      statusTotal,
      statusesAsParamString,
      range,
      rangeAsReqParams,
      search,
      searchAsReqParam,
      getTestResultsStatusCounts,
      testStatus,
      refreshStatusesCounts,
      getExplorerTestStatus,
      tableSettings,
      updateItemsPerPage,
      ITEMS_PER_PAGE_OPTIONS_DEFAULT,
    }
  },

  data() {
    return {
      headers: Object.freeze([
        {
          value: 'testInfo',
          width: 56,
        },
        {
          text: 'Test Name',
          value: 'testCase.name',
          sortable: true,
        },
        {
          text: 'Start Time',
          value: 'startTime',
          formatter: unixToDateTimeString,
          width: 142,
          sortable: true,
        },
        {
          text: 'End Time',
          value: 'endTime',
          formatter: unixToDateTimeString,
          width: 142,
          sortable: true,
        },
        {
          text: 'Duration',
          value: 'duration',
          formatter: this.formatDuration,
          width: 114,
          sortable: true,
        },
        {
          text: 'Status',
          value: 'status',
          width: 183,
          sortable: true,
        },
        {
          text: 'Result Info',
          value: 'resultInfo',
          width: 300,
          sortable: true,
        },
        {
          text: 'Audit',
          value: 'audit',
        }
      ]),

      selectActions: Object.freeze([
        {
          icon: 'mdi-stop',
          text: 'Stop Selected',
          iconColor: "red",
          click: (selectedItems) => {
            selectedItems = Object.keys(selectedItems).filter(key => selectedItems[key]);
            testCaseInfoService.stopTests(selectedItems);
          },
        },
        {
          icon: 'mdi-folder-zip',
          text: 'Detailed Report (zip)',
          click: (selectedItems) => {
            this.loading = true;
            requests.frameworkPostRequest('?f=testing&f2=testInfoPdf', 'getZippedReports', {
              responseType: 'ajax',
              selected: selectedItems,
            }, (result) => {
              this.loading = false;
              window.location.href = result.json['redirect'];
            });
          },
        },
      ]),

      loading: false,
    };
  },

  computed: {
    itemsRequestParams() {
      return [
        this.explorerId,
        this.statusesAsParamString,
        !this.$store.state.adminInfoSwitch,
        this.searchAsReqParam,
        this.tableSettings.itemsPerPage,
        this.rangeAsReqParams.from,
        this.rangeAsReqParams.to,
        this.page,
      ];
    },
  },

  methods: {
    getRowStyle(testStatus) {
      const currentProjectId = this.$store.state.project.id;
      if (testStatus.projectId!==currentProjectId) return 'background-color: var(--v-tableRowDeactivated-base); border-bottom: 1px solid var(--v-tableBorderDeactivated-base);';
      return '';
    },

    shaKey: shaKey,

    formatDuration(value, row) {
      const endTime = row['endTime'] ? row['endTime'] : parseInt(new Date().getTime() / 1000);
      return toColonDuration(endTime - row['startTime']);
    },

    async handleRangeUpdate(newRange) {
      this.range = newRange;
      this.setPageN(1)
      await this.refreshStatusesCounts();
    },

    /**
    * @param {string} searchStr
    */
    handleSearchUpdate: debounce(async function(searchStr) {
      if ((searchStr.length && searchStr.length < MIN_SEARCH_LENGTH) || this.search?.length < MIN_SEARCH_LENGTH && !searchStr.length) {
        this.search = searchStr;
        return
      }

      this.search = searchStr;
      this.setPageN(1)
      await this.refreshStatusesCounts();
    }, 500),
  },
});
</script>

<style lang="scss">
.explorer-test-results {
  width: 100%;
  height: 100%;

  &__total-button {
    flex: 0 0 auto;

    .v-btn:not(.v-btn--round).v-size--default {
      color: var(--v-text-inverted-base);
      font-size: 12px;
    }
  }

  &__status-bar {
    display: flex;
    width: 100%;
    gap: 2px;
    align-items: center;
  }

  &__statuses-split-btn {
    flex: 1;
  }

  &__search-text-field {
    flex: 1 1 auto;
    max-width: 350px;
  }

  &__status {
    display: flex;
    gap: 3px;
    align-items: center;
  }

  &__items-per-page-select {
    width: 120px;
    flex-shrink: 0;
  }
}

</style>
